/* eslint-disable jsx-a11y/anchor-is-valid */
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import * as React from "react";

import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
  Stack,
  useTheme,
} from "@mui/joy";

import { Delete } from "@mui/icons-material";
import { Case } from "../../models/Case";
import { useState } from "react";
import JobsRepository from "../../repositories/JobsRepository";
import { useQueryClient } from "react-query";
import ConfirmNewExportCasesToCsvModal from "./ConfirmNewExportCasesToCsvModal";
interface CreateExportCasesToCsvTableProps {
  organizationId: string;
  newCasesForExport: Case[];
  setNewCasesForExport: React.Dispatch<React.SetStateAction<Case[]>>;
}

const CreateExportCasesToCsvTable: React.FC<
  CreateExportCasesToCsvTableProps
> = ({ organizationId, newCasesForExport, setNewCasesForExport }) => {
  const theme = useTheme();

  const [confirmDeletionModalOpen, setConfirmDeletionModalOpen] =
    useState<boolean>(false);
  const [exportFilesData, setExportFilesData] = useState<boolean>(false);
  const allRows = [...newCasesForExport];
  const newIds = new Set(newCasesForExport.map((c) => c.id));
  const jobsRepository = new JobsRepository();
  const queryClient = useQueryClient();
  return (
    <Stack gap={2} paddingTop={2} maxHeight={"40%"} minHeight={"40%"}>
      <Stack gap={1}>
        <Button
          disabled={!(newCasesForExport.length > 0)}
          onClick={() => setConfirmDeletionModalOpen(true)}
        >
          Export
        </Button>
        <FormControl
          orientation="horizontal"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FormLabel>Export files data</FormLabel>
          <Checkbox
            sx={{ float: "right" }}
            checked={exportFilesData}
            onChange={(e) => setExportFilesData(e.target.checked)}
          />
        </FormControl>
      </Stack>
      <Sheet
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          stickyFooter
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: "5%",
                  minWidth: 48,
                  textAlign: "center",
                  padding: "12px 6px",
                }}
              ></th>

              <th
                style={{
                  width: "40%",
                  padding: "12px 6px",
                }}
              >
                Case Number
              </th>

              <th></th>
            </tr>
          </thead>

          <tbody>
            {allRows.map((deleteCaseJob) => {
              return (
                <tr
                  key={deleteCaseJob.id}
                  style={{
                    backgroundColor: newIds.has(deleteCaseJob.id)
                      ? theme.palette.primary.plainHoverBg
                      : "inherit",
                  }}
                >
                  <td style={{ textAlign: "center", width: 120 }}></td>
                  <td>
                    <Typography level="body-sm">
                      {deleteCaseJob.caseNumber}
                    </Typography>
                  </td>
                  <td
                    style={{
                      padding: "12px 6px",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      aria-label="Cancel"
                      size="sm"
                      color="danger"
                      onClick={() => {
                        setNewCasesForExport((prevRows) =>
                          prevRows.filter(
                            (entity) => entity.id !== deleteCaseJob.id
                          )
                        );
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Sheet>
      {confirmDeletionModalOpen ? (
        <ConfirmNewExportCasesToCsvModal
          open={confirmDeletionModalOpen}
          caseNumbers={newCasesForExport.map((c) => c.caseNumber)}
          exportFilesData={exportFilesData}
          onClose={() => {
            setConfirmDeletionModalOpen(false);
          }}
          onConfirm={async () => {
            await jobsRepository.startCsvGenerationFromCases(
              organizationId,
              newCasesForExport.map((c) => ({ id: c.id, caseNumber: c.caseNumber })),
              exportFilesData
            );
            queryClient.invalidateQueries("jobs");
            queryClient.invalidateQueries(["exportCasesToCsv", organizationId]);
            setNewCasesForExport([]);
            setConfirmDeletionModalOpen(false);
          }}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default CreateExportCasesToCsvTable;
