import { filterValidProperties, getNonNullProperties } from "../../helpers";
import { GetCaseFilesForCaseQueryOptions } from "../../repositories/CasesRepository";
import { JobEntityType } from "./JobEntityType";
import { JobRequestStatus } from "./JobRequestStatus";
import { JobType } from "./JobType";

export abstract class JobRequest {
  id!: string;
  organizationId!: string;
  organizationName!: string;
  status!: JobRequestStatus;
  type!: JobType;
  entityType!: JobEntityType;
}
export class DeleteCaseRequest extends JobRequest {
  type: JobType.Delete = JobType.Delete;
  entityType: JobEntityType = JobEntityType.Case;
  caseNumber!: string;
  migrationEntityId!: string;
}
export class DeleteCaseFilesRequest extends JobRequest {
  type: JobType.Delete = JobType.Delete;
  entityType: JobEntityType = JobEntityType.CaseFile;
  migrationEntityId!: string;
  caseNumber!: string;
  filePaths!: { [key: string]: string };
}
export class ExportCaseFilesToCsvRequest extends JobRequest {
  type: JobType.ExportCsv = JobType.ExportCsv;
  entityType: JobEntityType = JobEntityType.CaseFile;
  exportName!: string;
  exportedCsvUrl?: string;
  filesViewParameters!: GetCaseFilesForCaseQueryOptions;
  getNonNullFilesViewParameters(): Partial<GetCaseFilesForCaseQueryOptions> {
    this.filesViewParameters = filterValidProperties(
      this.filesViewParameters,
      new GetCaseFilesForCaseQueryOptions(),
      ["pageNumber", "pageSize"]
    );
    return getNonNullProperties<GetCaseFilesForCaseQueryOptions>(
      this.filesViewParameters
    );
  }
}
export class ExportCasesToCsvRequest extends JobRequest {
  type: JobType.ExportCsv = JobType.ExportCsv;
  entityType: JobEntityType = JobEntityType.Case;
  exportName!: string;
  exportedCsvUrl?: string;
}
export class ExportIntakesToCsvRequest extends JobRequest {
  type: JobType.ExportCsv = JobType.ExportCsv;
  entityType: JobEntityType = JobEntityType.Intake;
  exportName!: string;
  exportedCsvUrl?: string;
}
export class DeleteIntakeRequest extends JobRequest {
  type: JobType.Delete = JobType.Delete;
  entityType: JobEntityType = JobEntityType.Intake;
  intakeNumber!: string;
  migrationEntityId!: string;
}
export class DeleteIntakeFilesRequest extends JobRequest {
  type: JobType.Delete = JobType.Delete;
  entityType: JobEntityType = JobEntityType.IntakeFile;
  migrationEntityId!: string;
  intakeNumber!: string;
  filePaths!: { [key: string]: string };
}
export function transformJobRequest(job: JobRequest): JobRequest {
  switch (job.type) {
    case JobType.Delete:
      if (isDeleteCaseRequest(job)) {
        return Object.assign(new DeleteCaseRequest(), job);
      }
      if (isDeleteCaseFilesRequest(job)) {
        return Object.assign(new DeleteCaseFilesRequest(), job);
      }
      if (isDeleteIntakeRequest(job)) {
        return Object.assign(new DeleteIntakeRequest(), job);
      }
      if (isDeleteIntakeFilesRequest(job)) {
        return Object.assign(new DeleteIntakeFilesRequest(), job);
      }
      break;
    case JobType.ExportCsv:
      if (isGenerateCSVFromCaseRequest(job)) {
        return Object.assign(new ExportCasesToCsvRequest(), job);
      }
      if (isGenerateCSVFromCaseFilesRequest(job)) {
        return Object.assign(new ExportCaseFilesToCsvRequest(), job);
      }
      if (isGenerateCSVFromIntakeRequest(job)) {
        return Object.assign(new ExportIntakesToCsvRequest(), job);
      }
      break;
    default:
      throw new Error(`Unknown job type: ${job.type}`);
  }
  throw new Error(`Invalid job data: ${JSON.stringify(job)}`);
}
export function isDeleteCaseRequest(job: JobRequest): job is DeleteCaseRequest {
  return job.type === JobType.Delete && job.entityType === JobEntityType.Case;
}
export function isDeleteCaseFilesRequest(
  job: JobRequest
): job is DeleteCaseFilesRequest {
  return (
    job.type === JobType.Delete && job.entityType === JobEntityType.CaseFile
  );
}
export function isDeleteIntakeRequest(
  job: JobRequest
): job is DeleteIntakeRequest {
  return job.type === JobType.Delete && job.entityType === JobEntityType.Intake;
}
export function isDeleteIntakeFilesRequest(
  job: JobRequest
): job is DeleteIntakeFilesRequest {
  return (
    job.type === JobType.Delete && job.entityType === JobEntityType.IntakeFile
  );
}
export function isGenerateCSVFromCaseRequest(
  job: JobRequest
): job is ExportCasesToCsvRequest {
  return (
    job.type === JobType.ExportCsv && job.entityType === JobEntityType.Case
  );
}
export function isGenerateCSVFromCaseFilesRequest(
  job: JobRequest
): job is ExportCaseFilesToCsvRequest {
  return (
    job.type === JobType.ExportCsv && job.entityType === JobEntityType.CaseFile
  );
}
export function isGenerateCSVFromIntakeRequest(
  job: JobRequest
): job is ExportIntakesToCsvRequest {
  return (
    job.type === JobType.ExportCsv && job.entityType === JobEntityType.Intake
  );
}
