import {
  DeleteCaseFilesRequest,
  DeleteCaseRequest,
  DeleteIntakeFilesRequest,
  DeleteIntakeRequest,
  ExportCaseFilesToCsvRequest,
  ExportCasesToCsvRequest,
  ExportIntakesToCsvRequest,
  JobRequest,
  transformJobRequest,
} from "../models/Jobs/JobRequest";
import useApi from "../services/ApiService";
import { GetCaseFilesForCaseQueryOptions } from "./CasesRepository";

export class JobsRepository {
  private api = useApi();
  async getJobs(): Promise<JobRequest[]> {
    try {
      const response = await this.api.get("/api/jobs/all");
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(`Failed to get jobs: ${error}`);
    }
  }
  async deleteJob(jobId: string): Promise<void> {
    try {
      await this.api.delete(`/api/jobs/${jobId}`);
    } catch (error) {
      throw new Error(`Failed to abort job: ${error}`);
    }
  }
  async getCasesDeletionStatus(
    organizationId: string
  ): Promise<DeleteCaseRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/cases/delete/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(
        `Failed to fetch cases deletion status for organization with id: ${organizationId}`
      );
    }
  }
  async startCasesDeletion(
    organizationId: string,
    caseIds: string[]
  ): Promise<void> {
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/cases/delete`,
        {
          caseIds: caseIds,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(`Failed to start cases deletion with ids: ${caseIds}`);
    }
  }
  async getCaseFilesDeletionStatus(
    organizationId: string,
    caseId: string
  ): Promise<DeleteCaseFilesRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/case/${caseId}/files/delete/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(
        `Failed to fetch case files deletion status for organization with id: ${organizationId}`
      );
    }
  }
  async startCaseFilesDeletion(
    organizationId: string,
    caseId: string,
    fileIds: string[]
  ): Promise<void> {
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/case/${caseId}/files/delete`,
        {
          fileIds: fileIds,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Failed to start case files deletion with ids: ${fileIds}`
      );
    }
  }
  async getCasesCsvGenerationStatus(
    organizationId: string
  ): Promise<ExportCasesToCsvRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/cases/csv/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(
        `Failed to fetch csv generation from cases status for organization with id: ${organizationId}`
      );
    }
  }
  async startCsvGenerationFromCases(
    organizationId: string,
    cases: { id: string; caseNumber: string }[],
    exportFilesData: boolean
  ): Promise<void> {
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/cases/csv`,
        {
          cases: cases,
          exportFilesData: exportFilesData,
        }
      );
      return response.data;
    } catch (error) {
      const caseIds = cases.map((c) => c.id).join(", ");
      throw new Error(
        `Failed to start CSV generation from cases with ids: ${caseIds}`
      );
    }
  }
  async getCaseFilesCsvGenerationStatus(
    organizationId: string,
    caseId: string
  ): Promise<ExportCaseFilesToCsvRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/case/${caseId}/files/csv/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(
        `Failed to fetch csv generation from case files status for organization with id: ${organizationId}`
      );
    }
  }
  async startCsvGenerationFromCaseFiles(
    organizationId: string,
    caseId: string,
    options: GetCaseFilesForCaseQueryOptions = {}
  ): Promise<void> {
    const {
      status = null,
      extension = null,
      pageNumber = null,
      pageSize = null,
      orderBy = null,
      descending = true,
      nestedFolder1 = null,
      nestedFolder2 = null,
      filePath = null,
      minFileSize = null,
      maxFileSize = null,
      type = null,
    } = options;
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/case/${caseId}/files/csv`,
        null,
        {
          params: {
            Status: status,
            Extension: extension,
            NestedFolder1: nestedFolder1,
            NestedFolder2: nestedFolder2,
            FilePath: filePath,
            MinFileSize: minFileSize,
            MaxFileSize: maxFileSize,
            PageNumber: pageNumber,
            PageSize: pageSize,
            OrderBy: orderBy,
            Descending: descending,
            Type: type,
          },
          paramsSerializer: {
            indexes: true,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Failed to start CSV generation from case files caseId: ${caseId}`
      );
    }
  }
  async getIntakesDeletionStatus(
    organizationId: string
  ): Promise<DeleteIntakeRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/intakes/delete/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      // Handle error
      throw new Error(
        `Failed to fetch intakes deletion status for organization with id: ${organizationId}`
      );
    }
  }

  async startIntakesDeletion(
    organizationId: string,
    intakeIds: string[]
  ): Promise<void> {
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/intakes/delete`,
        {
          intakeIds: intakeIds,
        }
      );
      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(
        `Failed to start intakes deletion with ids: ${intakeIds}`
      );
    }
  }

  async getIntakeCsvGenerationStatus(
    organizationId: string
  ): Promise<ExportIntakesToCsvRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/intakes/csv/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      // Handle error
      throw new Error(
        `Failed to fetch csv generation from intake status for organization with id: ${organizationId}`
      );
    }
  }

  async startCsvGenerationFromIntakes(
    organizationId: string,
    intakes: { id: string; intakeNumber: string }[],
    exportFilesData: boolean
  ): Promise<void> {
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/intakes/csv`,
        {
          intakes: intakes,
          exportFilesData: exportFilesData,
        }
      );
      return response.data;
    } catch (error) {
      const intakeIds = intakes.map((i) => i.id).join(", ");
      throw new Error(
        `Failed to start csv generation from intake with ids: ${intakeIds}`
      );
    }
  }

  async startIntakeFilesDeletion(
    organizationId: string,
    intakeId: string,
    fileIds: string[]
  ): Promise<void> {
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/intake/${intakeId}/files/delete`,
        {
          fileIds: fileIds,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Failed to start intake files deletion with ids: ${fileIds}`
      );
    }
  }

  async getIntakeFilesDeletionStatus(
    organizationId: string,
    intakeId: string
  ): Promise<DeleteIntakeFilesRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/intake/${intakeId}/files/delete/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(
        `Failed to fetch intake files deletion status for organization with id: ${organizationId}`
      );
    }
  }
}

export default JobsRepository;
