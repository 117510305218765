import {SelectOption} from "../../components/common/GenericSelect";

export enum MigrationFlagStatus {
  UnResolved = "unresolved",
  Resolved = "resolved",
  Acknowledged = "acknowledged",
}

export const migrationFlagStatusSelectOptions: SelectOption<MigrationFlagStatus>[] = [
  { id: MigrationFlagStatus.UnResolved, label: "Blocked", color: "danger" },
  { id: MigrationFlagStatus.Resolved, label: "Allowed", color: "lime" },
  { id: MigrationFlagStatus.Acknowledged, label: "Acknowledged", color: "yellow" },
];