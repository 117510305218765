/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Order } from "../../helpers/TableHelpers";
import { Case } from "../../models/Case";
import {
  MigrationStatus,
  MigrationStatusColors,
  MigrationStatusLabels,
} from "../../models/MigrationStatus";
import { FlagType } from "../../models/ClientConfiguration/FlaggingConfiguration";
import CasesRepository from "../../repositories/CasesRepository";
import { ClearableSelect } from "../common/ClearableSelect";
import MigrationStatusChip from "../common/MigrationStatusChip";
import SortableTableColumnHeader from "../common/SortableTableColumnHeader";
import ConfirmCaseFlagUpdateModal from "./ConfirmCaseFlagUpdateModal";
import ConfirmCaseBlockUpdateModal from "./ConfirmCaseBlockUpdateModal";
import GenericSelect from "../common/GenericSelect";
import {
  MigrationFlagStatus,
  migrationFlagStatusSelectOptions,
} from "../../models/Flags/MigrationFlagStatus";
import { Button, IconButton, Select, Skeleton } from "@mui/joy";
import { JobRequestStatus } from "../../models/Jobs/JobRequestStatus";
import { DeleteCaseRequest } from "../../models/Jobs/JobRequest";
import { JobType } from "../../models/Jobs/JobType";
import { JobEntityType } from "../../models/Jobs/JobEntityType";
import { ActiveCaseJobsExplorer } from "../../pages/CasesPage";
import FlagTypeChip from "../common/FlagTypeChip";

// icons
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import TableViewIcon from "@mui/icons-material/TableView";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import BlockIcon from "@mui/icons-material/Block";

interface CasesTableProps {
  organizationId: string;
  activeJobsExplorer: ActiveCaseJobsExplorer;
  newCasesForDeletion: DeleteCaseRequest[];
  setNewCasesForDeletion: React.Dispatch<
    React.SetStateAction<DeleteCaseRequest[]>
  >;
  newCasesForExport: Case[];
  setNewCasesForExport: React.Dispatch<React.SetStateAction<Case[]>>;
}

const CasesTable: React.FC<CasesTableProps> = ({
  organizationId,
  activeJobsExplorer,
  newCasesForDeletion,
  setNewCasesForDeletion,
  newCasesForExport,
  setNewCasesForExport,
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [sort, setSort] = React.useState<string>("status");
  const [lockFilter, setLockFilter] = React.useState<boolean | null>(null);
  const [statusFilter, setStatusFilter] = React.useState<string | null>(null);
  const [searchForCaseNumber, setSearchForCaseNumber] = useState<string | null>(
    ""
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [cases, setCases] = useState<Case[]>([]);
  const [rows, setRows] = useState(0);
  const [isFlagModalOpen, setIsFlagModalOpen] = React.useState<boolean>(false);
  const [dirtyFlagStatus, setDirtyFlagStatus] = useState<MigrationFlagStatus>();
  const [dirtyCaseId, setDirtyCaseId] = useState<string>("");
  const [dirtyCaseNumber, setDirtyCaseNumber] = useState<string>("");
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [isBlockModalOpen, setIsBlockModalOpen] =
    React.useState<boolean>(false);

  const casesRepository = new CasesRepository();
  const navigate = useNavigate();
  const casesQuery = useQuery(
    [
      "cases",
      organizationId,
      searchForCaseNumber,
      page,
      rowsPerPage,
      statusFilter,
      lockFilter,
      sort,
      order,
    ],
    async () => {
      const cases = await casesRepository.getCases({
        organizationId: organizationId,
        caseNumber: searchForCaseNumber ? [searchForCaseNumber] : null,
        descending: order === "desc",
        orderBy: sort,
        status: statusFilter ? [statusFilter] : null,
        isLocked: lockFilter,
        pageNumber: page + 1,
        pageSize: rowsPerPage,
      });
      return cases;
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000 + 1, // Refetch data every 2 minutes
      refetchIntervalInBackground: true,
      refetchOnMount: true,
    }
  );

  useEffect(() => {
    if (casesQuery.data?.data) {
      setCases(casesQuery.data?.data);
      setRows(casesQuery.data.paging?.totalCount ?? 0);
    }
  }, [casesQuery]);
  const handleSearch = (newCaseNumber: string) => {
    setSearchForCaseNumber(newCaseNumber ?? null);
  };
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };
  const changeSort = (newSort: string) => {
    setPage(0);
    setSort(newSort);
  };
  const changeOrder = (newOrder: Order) => {
    setPage(0);
    setOrder(newOrder);
  };
  const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };
  const handleDelete = (caseId: string, caseNumber: string) => {
    if (!disabledRows.has(caseId)) {
      setNewCasesForDeletion((prevRows) => [
        ...prevRows,
        {
          migrationEntityId: caseId,
          caseNumber: caseNumber,
          status: JobRequestStatus.New,
          type: JobType.Delete,
          entityType: JobEntityType.Case,
          organizationId: organizationId,
        } as DeleteCaseRequest,
      ]);
    }
  };

  const handleAddCaseForExport = (caseEntity: Case) => {
    if (!disabledRows.has(caseEntity.id)) {
      setNewCasesForExport((prevRows) => [...prevRows, caseEntity]);
    }
  };

  const getLabelDisplayedRowsTo = () => {
    if (rows === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1 ? rows : Math.min(rows, (page + 1) * rowsPerPage);
  };

  const getDisabledRows = () => {
    switch (activeJobsExplorer) {
      case ActiveCaseJobsExplorer.DeleteCases:
        return new Set(newCasesForDeletion.map((c) => c.migrationEntityId));
      case ActiveCaseJobsExplorer.ExportCsv:
        return new Set(newCasesForExport.map((c) => c.id));
    }
  };

  function labelDisplayedRows({
    from,
    to,
    count,
  }: {
    from: number;
    to: number;
    count: number;
  }) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
  }

  const getActiveButtonForExplorer = (
    caseEntity: Case,
    isDisabled: boolean
  ) => {
    switch (activeJobsExplorer) {
      case ActiveCaseJobsExplorer.DeleteCases:
        return (
          <IconButton
            aria-label="Delete"
            disabled={isDisabled}
            size="sm"
            color="danger"
            onClick={() => handleDelete(caseEntity.id, caseEntity.caseNumber)}
          >
            <DeleteIcon />
          </IconButton>
        );
      case ActiveCaseJobsExplorer.ExportCsv:
        return (
          <IconButton
            aria-label="Csv"
            disabled={isDisabled}
            size="sm"
            color="success"
            onClick={() => handleAddCaseForExport(caseEntity)}
          >
            <TableViewIcon />
          </IconButton>
        );
      default:
        <></>;
    }
  };

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Status</FormLabel>
        <ClearableSelect
          value={statusFilter}
          color={MigrationStatusColors[statusFilter as MigrationStatus]}
          onChange={(newValue) => {
            setPage(0);
            setStatusFilter(newValue);
          }}
          size="sm"
          placeholder="Filter by status"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
        >
          {Object.values(MigrationStatus)
            .filter((v) => v !== MigrationStatus.NotFound)
            .map((status, idx) => {
              const migrationStatus = status as MigrationStatus;
              return (
                <Option
                  key={idx}
                  value={migrationStatus}
                  color={MigrationStatusColors[migrationStatus]}
                >
                  {MigrationStatusLabels[migrationStatus]}
                </Option>
              );
            })}
        </ClearableSelect>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Lock</FormLabel>
        <ClearableSelect
          value={null}
          onChange={(newValue) => {
            setPage(0);
            if (newValue === null || newValue === "all") {
              setLockFilter(null);
            } else {
              setLockFilter(newValue === "locked");
            }
          }}
          size="sm"
          placeholder="Filter by lock status"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
        >
          <Option key={1} value={"locked"}>
            Locked
          </Option>
          <Option key={2} value={"unlocked"}>
            Unlocked
          </Option>
        </ClearableSelect>
      </FormControl>
    </React.Fragment>
  );

  const handleFlagStatusModal = (
    caseNumber: string,
    caseId: string,
    flagStatus: MigrationFlagStatus,
    isOpen: boolean
  ) => {
    setIsFlagModalOpen(isOpen);
    setDirtyCaseNumber(caseNumber);
    setDirtyCaseId(caseId);
    setDirtyFlagStatus(flagStatus);
  };

  const handleBlockModal = (
    caseNumber: string,
    caseId: string,
    isBlocked: boolean,
    isOpen: boolean
  ) => {
    setIsBlockModalOpen(isOpen);
    setDirtyCaseNumber(caseNumber);
    setDirtyCaseId(caseId);
    setIsBlocked(isBlocked);
  };

  const { isError, isFetching } = casesQuery;
  const disabledRows = getDisabledRows();

  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          pb: 0.5,
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for case</FormLabel>
          <Input
            size="sm"
            placeholder="Case number"
            startDecorator={<SearchIcon />}
            onBlur={(event) => handleSearch(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                const target = event.target as HTMLInputElement;
                target.blur();
              }
            }}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="CasesTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Skeleton variant="inline" loading={isFetching || isError}>
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            stickyFooter
            hoverRow
            sx={{
              "--TableCell-headBackground":
                "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground":
                "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: "1.5%",
                    minWidth: 48,
                    textAlign: "center",
                    padding: "12px 6px",
                  }}
                ></th>
                <th
                  style={{
                    width: "15%",
                    padding: "12px 6px",
                  }}
                >
                  <SortableTableColumnHeader
                    label={"Number"}
                    sortProperty={"caseNumber"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>
                <th style={{ width: "8%", padding: "12px 6px" }}>Lock</th>

                <th style={{ width: "14%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Status"}
                    sortProperty={"status"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>

                <th style={{ width: "10%", padding: "12px 6px" }}>Progress</th>

                <th style={{ width: "12%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Files"}
                    sortProperty={"totalNumberOfFiles"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>

                <th style={{ width: "12%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Size"}
                    sortProperty={"totalFilesSize"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>

                <th style={{ width: "13%", padding: "12px 6px" }}>Flag Type</th>

                <th style={{ width: "18%", padding: "12px 6px" }}>
                  Blocked By
                </th>

                <th style={{ width: "20%", padding: "12px 6px" }}>
                  Flag Status
                </th>

                <th
                  style={{
                    width: "10%",
                    maxWidth: 10,
                    padding: "12px 6px",
                  }}
                ></th>
              </tr>
            </thead>

            <tbody>
              {cases.map((caseEntity) => {
                const migratedPercentage =
                  caseEntity.statistics.totalNumberOfFiles > 0
                    ? (caseEntity.statistics.filesMigrated /
                        caseEntity.statistics.totalNumberOfFiles) *
                      100
                    : -1;

                const migrationFlagStatusSelectOption =
                  migrationFlagStatusSelectOptions.find(
                    (option) => option.id === caseEntity?.flagStatus
                  ) || migrationFlagStatusSelectOptions[0];
                const isDisabled = disabledRows.has(caseEntity.id);

                return (
                  <tr
                    key={caseEntity.id}
                    onDoubleClick={() =>
                      navigate(
                        `/organization/${organizationId}/case/${caseEntity.id}`
                      )
                    }
                    style={{ opacity: isDisabled ? 0.2 : 1 }}
                  >
                    <td></td>
                    <td>
                      <Typography level="body-sm">
                        {caseEntity.caseNumber}
                      </Typography>
                    </td>
                    <td>
                      {caseEntity.isLocked ? (
                        <Chip variant="soft" size="sm" color="primary">
                          Locked
                        </Chip>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>
                      <MigrationStatusChip status={caseEntity.status} />
                    </td>
                    <td>
                      {caseEntity.statistics.totalNumberOfFiles > 0 ? (
                        <Chip
                          sx={{ textAlign: "center" }}
                          variant="soft"
                          size="sm"
                          color={
                            migratedPercentage > 99.99 ? "success" : "primary"
                          }
                        >
                          {migratedPercentage % 1 > 0.99 ||
                          migratedPercentage % 1 < 0.01
                            ? `${migratedPercentage.toFixed(0)}%`
                            : `${migratedPercentage.toFixed(2)}%`}
                        </Chip>
                      ) : (
                        <Chip variant="soft" size="sm" color="danger">
                          No files
                        </Chip>
                      )}
                    </td>
                    <td>
                      <Chip variant="soft" size="sm" color="neutral">
                        {caseEntity.statistics.totalNumberOfFiles ?? 0}
                      </Chip>
                    </td>
                    <td>
                      <Chip variant="soft" size="sm" color="neutral">
                        {caseEntity.statistics.filesSizeInMb.toFixed(2)} MB
                      </Chip>
                    </td>

                    <td>
                      {caseEntity.flagType ? (
                        <FlagTypeChip flagType={caseEntity.flagType} />
                      ) : (
                        <></>
                      )}
                    </td>

                    <td>
                      <Typography
                        level="body-sm"
                        sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                      >
                        {caseEntity.flaggedBy}
                      </Typography>
                    </td>

                    <td>
                      {caseEntity.flagType &&
                      caseEntity.flagType ==
                        FlagType.MigrationEntityWithMoreThan10KFiles ? (
                        <GenericSelect
                          size="sm"
                          options={migrationFlagStatusSelectOptions}
                          placeholder="Select status"
                          inputValue={migrationFlagStatusSelectOption}
                          setValue={(value) =>
                            handleFlagStatusModal(
                              caseEntity.caseNumber,
                              caseEntity.id,
                              value.id,
                              true
                            )
                          }
                        />
                      ) : caseEntity.flagType == null ||
                        caseEntity.flagType ===
                          FlagType.MigrationEntityBlockedByUser ? (
                        <Button
                          sx={{
                            width: "100%",
                            maxWidth: 150,
                            justifyContent: "flex-start",
                            textAlign: "left",
                          }}
                          variant="soft"
                          aria-label="Block/Unblock"
                          disabled={isDisabled}
                          size="sm"
                          color={
                            caseEntity.flagType ===
                            FlagType.MigrationEntityBlockedByUser
                              ? "lime"
                              : "warning"
                          }
                          startDecorator={
                            caseEntity.flagType ===
                            FlagType.MigrationEntityBlockedByUser ? (
                              <LockOpenIcon />
                            ) : (
                              <BlockIcon />
                            )
                          }
                          onClick={() => {
                            caseEntity.flagType ===
                            FlagType.MigrationEntityBlockedByUser
                              ? handleBlockModal(
                                  caseEntity.caseNumber,
                                  caseEntity.id,
                                  false,
                                  true
                                )
                              : handleBlockModal(
                                  caseEntity.caseNumber,
                                  caseEntity.id,
                                  true,
                                  true
                                );
                          }}
                        >
                          {caseEntity.flagType ===
                          FlagType.MigrationEntityBlockedByUser
                            ? "Unblock"
                            : "Block"}
                        </Button>
                      ) : (
                        <></>
                      )}
                    </td>

                    <td>
                      {getActiveButtonForExplorer(caseEntity, isDisabled)}
                    </td>
                  </tr>
                );
              })}
            </tbody>

            <tfoot>
              <tr>
                <td colSpan={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      justifyContent: "flex-end",
                    }}
                  >
                    <FormControl orientation="horizontal" size="sm">
                      <FormLabel>Rows per page:</FormLabel>
                      <Select
                        onChange={handleChangeRowsPerPage}
                        value={rowsPerPage}
                      >
                        <Option value={25}>25</Option>
                        <Option value={50}>50</Option>
                        <Option value={100}>100</Option>
                      </Select>
                    </FormControl>

                    <Typography textAlign="center" sx={{ minWidth: 80 }}>
                      {labelDisplayedRows({
                        from: rows === 0 ? 0 : page * rowsPerPage + 1,
                        to: getLabelDisplayedRowsTo(),
                        count: rows === -1 ? -1 : rows,
                      })}
                    </Typography>

                    <Box sx={{ display: "flex", gap: 1 }}>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={page === 0}
                        onClick={() => handleChangePage(page - 1)}
                        sx={{ bgcolor: "background.surface" }}
                      >
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={
                          rows !== -1
                            ? page >= Math.ceil(rows / rowsPerPage) - 1
                            : false
                        }
                        onClick={() => handleChangePage(page + 1)}
                        sx={{ bgcolor: "background.surface" }}
                      >
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </td>
              </tr>
            </tfoot>
          </Table>
        </Skeleton>
      </Sheet>

      {/* Flags other than block Modal */}
      {dirtyFlagStatus ? (
        <ConfirmCaseFlagUpdateModal
          open={isFlagModalOpen}
          flagStatus={dirtyFlagStatus}
          caseNumber={dirtyCaseNumber}
          onClose={() => {
            handleFlagStatusModal(
              "",
              "",
              MigrationFlagStatus.UnResolved,
              false
            );
          }}
          onConfirm={async () => {
            await casesRepository.updateCaseFlag(dirtyCaseId, dirtyFlagStatus);
            await casesQuery.refetch();
            handleFlagStatusModal(
              "",
              "",
              MigrationFlagStatus.UnResolved,
              false
            );
          }}
        />
      ) : (
        <></>
      )}

      {/* Block Modal */}
      <ConfirmCaseBlockUpdateModal
        open={isBlockModalOpen}
        isBlocked={isBlocked}
        caseNumber={dirtyCaseNumber}
        onClose={() => {
          handleBlockModal("", "", false, false);
        }}
        onConfirm={async () => {
          await casesRepository.updateCaseBlock(dirtyCaseId, isBlocked);
          await casesQuery.refetch();
          handleBlockModal("", "", false, false);
        }}
      />
    </React.Fragment>
  );
};

export default CasesTable;
