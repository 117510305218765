import React from "react";
import { Box, Chip, IconButton, Tooltip, Typography } from "@mui/joy";
import {
  JobRequest,
  DeleteCaseRequest,
  ExportCasesToCsvRequest,
  DeleteCaseFilesRequest,
  DeleteIntakeFilesRequest,
  DeleteIntakeRequest,
  ExportIntakesToCsvRequest,
  ExportCaseFilesToCsvRequest,
} from "../../models/Jobs/JobRequest";
import { JobEntityTypeColors } from "../../models/Jobs/JobEntityType";
import { Download, VisibilityOutlined } from "@mui/icons-material";
import { JobType } from "../../models/Jobs/JobType";

type ChipBreakpoint = "sm" | "md" | "lg";

interface JobInfoViewProps {
  jobRequest: JobRequest;
  size?: ChipBreakpoint;
}

const DeleteCaseRequestInfo: React.FC<{
  jobRequest: DeleteCaseRequest;
  size: ChipBreakpoint;
}> = ({ jobRequest, size }) => {
  return (
    <Box>
      <Chip
        variant="soft"
        size={size}
        color={JobEntityTypeColors[jobRequest.entityType]}
      >
        Case Number: {jobRequest.caseNumber}
      </Chip>
    </Box>
  );
};
const DeleteCaseFilesRequestInfo: React.FC<{
  jobRequest: DeleteCaseFilesRequest;
  size: ChipBreakpoint;
}> = ({ jobRequest, size }) => {
  const fileCount = Object.keys(jobRequest.filePaths).length;

  return (
    <Box
      sx={{
        display: "flex",
        my: 1,
        gap: 1,
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "start", sm: "center" },
        flexWrap: "wrap",
        justifyContent: "left",
      }}
    >
      <Chip
        variant="soft"
        size={size}
        color={JobEntityTypeColors[jobRequest.entityType]}
      >
        Case Number: {jobRequest.caseNumber}
      </Chip>
      <Chip
        variant="soft"
        size={size}
        color={JobEntityTypeColors[jobRequest.entityType]}
      >
        {fileCount} files
      </Chip>
      <Tooltip
        variant="outlined"
        arrow
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              p: 1,
            }}
          >
            <Box sx={{ display: "flex", gap: 1, width: "100%", mt: 1 }}>
              <div>
                <Typography sx={{ fontWeight: "lg", fontSize: "sm" }}>
                  File paths for deletion
                </Typography>
                <Typography
                  textColor="text.secondary"
                  sx={{ fontSize: "sm", mb: 1 }}
                >
                  {Object.values(jobRequest.filePaths).map((path, index) => (
                    <div key={index}>{path}</div>
                  ))}
                </Typography>
              </div>
            </Box>
          </Box>
        }
      >
        <VisibilityOutlined />
      </Tooltip>
    </Box>
  );
};

const DeleteIntakeRequestInfo: React.FC<{
  jobRequest: DeleteIntakeRequest;
  size: ChipBreakpoint;
}> = ({ jobRequest, size }) => {
  return (
    <Box>
      <Chip
        variant="soft"
        size={size}
        color={JobEntityTypeColors[jobRequest.entityType]}
      >
        Intake Number: {jobRequest.intakeNumber}
      </Chip>
    </Box>
  );
};
const DeleteIntakeFilesRequestInfo: React.FC<{
  jobRequest: DeleteIntakeFilesRequest;
  size: ChipBreakpoint;
}> = ({ jobRequest, size }) => {
  const fileCount = Object.keys(jobRequest.filePaths).length;

  return (
    <Box
      sx={{
        display: "flex",
        my: 1,
        gap: 1,
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "start", sm: "center" },
        flexWrap: "wrap",
        justifyContent: "left",
      }}
    >
      <Chip
        variant="soft"
        size={size}
        color={JobEntityTypeColors[jobRequest.entityType]}
      >
        Intake Number: {jobRequest.intakeNumber}
      </Chip>
      <Chip
        variant="soft"
        size={size}
        color={JobEntityTypeColors[jobRequest.entityType]}
      >
        {fileCount} files
      </Chip>
      <Tooltip
        variant="outlined"
        arrow
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              p: 1,
            }}
          >
            <Box sx={{ display: "flex", gap: 1, width: "100%", mt: 1 }}>
              <div>
                <Typography sx={{ fontWeight: "lg", fontSize: "sm" }}>
                  File paths for deletion
                </Typography>
                <Typography
                  textColor="text.secondary"
                  sx={{ fontSize: "sm", mb: 1 }}
                >
                  {Object.values(jobRequest.filePaths).map((path, index) => (
                    <div key={index}>{path}</div>
                  ))}
                </Typography>
              </div>
            </Box>
          </Box>
        }
      >
        <VisibilityOutlined />
      </Tooltip>
    </Box>
  );
};

const GenerateCSVFromCaseRequestInfo: React.FC<{
  jobRequest: ExportCasesToCsvRequest;
  size: ChipBreakpoint;
}> = ({ jobRequest, size }) => {
  const handleDownload = () => {
    if (jobRequest.exportedCsvUrl) {
      window.open(jobRequest.exportedCsvUrl, "_blank");
    }
  };
  return (
    <Box display="flex" alignItems="center">
      <Chip
        variant="soft"
        size={size}
        color={JobEntityTypeColors[jobRequest.entityType]}
      >
        {jobRequest.exportName}
      </Chip>
      <IconButton
        aria-label="Download CSV"
        size="sm"
        color="primary"
        onClick={handleDownload}
        disabled={!jobRequest.exportedCsvUrl}
        sx={{ marginLeft: 1 }}
      >
        <Download />
      </IconButton>
    </Box>
  );
};
const ExportCaseFilesToCsvRequestInfo: React.FC<{
  jobRequest: ExportCaseFilesToCsvRequest;
  size: ChipBreakpoint;
}> = ({ jobRequest, size }) => {
  const handleDownload = () => {
    if (jobRequest.exportedCsvUrl) {
      window.open(jobRequest.exportedCsvUrl, "_blank");
    }
  };
  const nonNullFilesViewParameters = jobRequest.getNonNullFilesViewParameters();

  return (
    <Box display="flex" alignItems="center">
      <Chip
        variant="soft"
        size={size}
        color={JobEntityTypeColors[jobRequest.entityType]}
      >
        {jobRequest.exportName}
      </Chip>
      <IconButton
        aria-label="Download CSV"
        size="sm"
        color="primary"
        onClick={handleDownload}
        disabled={!jobRequest.exportedCsvUrl}
        sx={{ marginLeft: 1 }}
      >
        <Download />
      </IconButton>
      <Tooltip
        variant="outlined"
        arrow
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              p: 1,
            }}
          >
            <Box sx={{ display: "flex", gap: 1, width: "100%", mt: 1 }}>
              <div>
                <Typography sx={{ fontWeight: "lg", fontSize: "sm" }}>
                  Exported csv query parameters
                </Typography>
                <Box>
                  {Object.entries(nonNullFilesViewParameters).map(
                    ([key, value]) => (
                      <Typography key={key}>
                        {key}: {JSON.stringify(value)}
                      </Typography>
                    )
                  )}
                </Box>
              </div>
            </Box>
          </Box>
        }
      >
        <VisibilityOutlined />
      </Tooltip>
    </Box>
  );
};

const GenerateCSVFromIntakeRequestInfo: React.FC<{
  jobRequest: ExportIntakesToCsvRequest;
  size: ChipBreakpoint;
}> = ({ jobRequest, size }) => {
  const handleDownload = () => {
    if (jobRequest.exportedCsvUrl) {
      window.open(jobRequest.exportedCsvUrl, "_blank");
    }
  };
  return (
    <Box display="flex" alignItems="center">
      <Chip
        variant="soft"
        size={size}
        color={JobEntityTypeColors[jobRequest.entityType]}
      >
        {jobRequest.exportName}
      </Chip>
      <IconButton
        aria-label="Download CSV"
        size="sm"
        color="primary"
        onClick={handleDownload}
        disabled={!jobRequest.exportedCsvUrl}
        sx={{ marginLeft: 1 }}
      >
        <Download />
      </IconButton>
    </Box>
  );
};

const JobInfoView: React.FC<JobInfoViewProps> = ({
  jobRequest,
  size = "sm",
}) => {
  switch (jobRequest.type) {
    case JobType.Delete:
      if (jobRequest instanceof DeleteCaseRequest) {
        return <DeleteCaseRequestInfo jobRequest={jobRequest} size={size} />;
      }
      if (jobRequest instanceof DeleteCaseFilesRequest) {
        return (
          <DeleteCaseFilesRequestInfo jobRequest={jobRequest} size={size} />
        );
      }
      if (jobRequest instanceof DeleteIntakeRequest) {
        return <DeleteIntakeRequestInfo jobRequest={jobRequest} size={size} />;
      }
      if (jobRequest instanceof DeleteIntakeFilesRequest) {
        return (
          <DeleteIntakeFilesRequestInfo jobRequest={jobRequest} size={size} />
        );
      }
      break;
    case JobType.ExportCsv:
      if (jobRequest instanceof ExportCasesToCsvRequest) {
        return (
          <GenerateCSVFromCaseRequestInfo jobRequest={jobRequest} size={size} />
        );
      }
      if (jobRequest instanceof ExportIntakesToCsvRequest) {
        return (
          <GenerateCSVFromIntakeRequestInfo
            jobRequest={jobRequest}
            size={size}
          />
        );
      }
      if (jobRequest instanceof ExportCaseFilesToCsvRequest) {
        return (
          <ExportCaseFilesToCsvRequestInfo
            jobRequest={jobRequest}
            size={size}
          />
        );
      }
      break;
    default:
      return null;
  }
  return null;
};

export default JobInfoView;
