import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
  Tabs,
  Tab,
  TabPanel,
  TabList,
  tabClasses,
  Divider,
} from "@mui/joy";
import { useParams } from "react-router-dom";
import { useState } from "react";
import ExportIntakesToCsvStatusTable from "../components/Intakes/ExportIntakesToCsvStatusTable";
import IntakesTable from "../components/Intakes/IntakesTable";
import DeleteIntakesTable from "../components/Intakes/DeleteIntakesTable";
import { Intake } from "../models/Intake";
import { DeleteIntakeRequest } from "../models/Jobs/JobRequest";
import CreateExportIntakesToCsvTable from "../components/Intakes/CreateExportIntakesToCsvTable";

export enum ActiveIntakeJobsExplorer {
  DeleteIntakes = 0,
  GenerateCSV = 1,
}

const IntakesPage = () => {
  const { organizationId } = useParams();
  const [newIntakesForDeletion, setNewIntakesForDeletion] = useState<
    DeleteIntakeRequest[]
  >([]);
  const [newIntakesForExport, setNewIntakesForExport] = useState<Intake[]>([]);
  const [selectedTab, setSelectedTab] = useState<ActiveIntakeJobsExplorer>(
    ActiveIntakeJobsExplorer.DeleteIntakes
  );
  return (
    <Stack maxHeight={"92vh"}>
      <Box
        sx={{
          display: "flex",
          my: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">Intakes</Typography>
      </Box>
      <Grid container spacing={2} display={"inline-flex"} maxHeight={"100%"}>
        <Grid xs={12} lg={9} display={"flex"} maxHeight={"100%"}>
          <Card>
            <IntakesTable
              organizationId={organizationId ?? ""}
              activeJobsExplorer={selectedTab}
              newIntakesForDeletion={newIntakesForDeletion}
              setNewIntakesForDeletion={setNewIntakesForDeletion}
              newIntakesForExport={newIntakesForExport}
              setNewIntakesForExport={setNewIntakesForExport}
            />
          </Card>
        </Grid>
        <Grid
          xs={12}
          lg={3}
          display={"flex"}
          maxHeight={"100%"}
          height={"100%"}
        >
          <Card sx={{ width: "100%", padding: 0 }}>
            <Tabs
              value={selectedTab}
              sx={{
                maxHeight: "100%",
                borderRadius: "sm",
                overflow: "auto",
                height: "100%",
              }}
              onChange={(event, newValue) =>
                setSelectedTab(newValue as ActiveIntakeJobsExplorer)
              }
            >
              <TabList
                disableUnderline
                tabFlex={1}
                sx={{
                  [`& .${tabClasses.root}`]: {
                    fontSize: "sm",
                    fontWeight: "md",
                    [`&[aria-selected="true"]`]: {
                      bgcolor: "background.surface",
                    },
                    [`&.${tabClasses.focusVisible}`]: {
                      outlineOffset: "-4px",
                    },
                  },
                }}
              >
                <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                  Delete intakes
                </Tab>
                <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                  Export csv
                </Tab>
              </TabList>
              <TabPanel
                value={ActiveIntakeJobsExplorer.DeleteIntakes}
                sx={{
                  flex: 1,
                  overflow: "auto",
                  maxHeight: "100%",
                  paddingTop: 0,
                }}
              >
                <Card
                  sx={{
                    padding: 0,
                    border: 0,
                    width: "100%",
                    maxHeight: "100%",
                  }}
                >
                  <DeleteIntakesTable
                    organizationId={organizationId ?? ""}
                    newIntakesForDeletion={newIntakesForDeletion}
                    setNewIntakesForDeletion={setNewIntakesForDeletion}
                  />
                </Card>
              </TabPanel>
              <TabPanel
                value={ActiveIntakeJobsExplorer.GenerateCSV}
                sx={{
                  flex: 1,
                  overflow: "auto",
                  maxHeight: "100%",
                  height: "100%",
                  paddingTop: 0,
                }}
              >
                {/* <Stack height={"100%"} spacing={2}> */}
                <Stack height={"100%"} spacing={2}>
                  {newIntakesForExport.length > 0 && (
                    <>
                      <Divider sx={{ paddingTop: 2 }}>
                        <b>New</b>
                      </Divider>
                      <CreateExportIntakesToCsvTable
                        organizationId={organizationId ?? ""}
                        newIntakesForExport={newIntakesForExport}
                        setNewIntakesForExport={setNewIntakesForExport}
                      />
                      <Divider>
                        <b>Existing exports</b>
                      </Divider>
                    </>
                  )}
                  <Card
                    sx={{
                      padding: 0,
                      border: 0,
                      width: "100%",
                      height: "50%",
                    }}
                  >
                    <ExportIntakesToCsvStatusTable
                      organizationId={organizationId ?? ""}
                    />
                  </Card>
                </Stack>
              </TabPanel>
            </Tabs>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default IntakesPage;
