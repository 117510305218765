import {
  Box,
  Card,
  Chip,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  tabClasses,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import UpdateIntakeFlagModal from "../components/Intakes/ConfirmIntakeFlagUpdateModal";
import MigrationStatusChip from "../components/common/MigrationStatusChip";
import {
  MigrationStatus,
  MigrationStatusColors,
} from "../models/MigrationStatus";
import GenericSelect from "../components/common/GenericSelect";
import {
  MigrationFlagStatus,
  migrationFlagStatusSelectOptions,
} from "../models/Flags/MigrationFlagStatus";
import React from "react";
import IntakesRepository from "../repositories/IntakesRepository";
import { Intake } from "../models/Intake";
import { IntakeFile } from "../models/IntakeFile";
import IntakeFilesTable from "../components/Intake/IntakeFilesTable";
import CreateDeleteIntakeFilesRequestTable from "../components/Intake/CreateDeleteIntakeFilesRequestTable";
import DeleteIntakeFilesStatusTable from "../components/Intake/DeleteIntakeFilesStatusTable";

export enum ActiveIntakeFilesJobsExplorer {
  DeleteIntakeFiles = 0,
  ExportCsv = 1,
}

const IntakePage = () => {
  const { organizationId, intakeId } = useParams();
  const intakesRepository = new IntakesRepository();
  const [intakeEntity, setIntake] = useState<Intake | undefined>(undefined);
  const [dirtyFlagStatus, setDirtyFlagStatus] = useState<MigrationFlagStatus>();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [newIntakeFilesForDeletion, setNewIntakeFilesForDeletion] = useState<
    IntakeFile[]
  >([]);
  const [selectedTab, setSelectedTab] = useState<ActiveIntakeFilesJobsExplorer>(
    ActiveIntakeFilesJobsExplorer.DeleteIntakeFiles
  );

  const queryClient = useQueryClient();
  const intakeQuery = useQuery(
    ["intake", intakeId],
    async () => {
      return await intakesRepository.getIntake(intakeId ?? "");
    },
    {
      cacheTime: 2 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
      refetchOnMount: true,
    }
  );

  useEffect(() => {
    if (intakeQuery.data) {
      setIntake(intakeQuery.data);
    }
  }, [intakeQuery.data, intakeId]);

  const { isLoading, isError } = intakeQuery;
  if (isLoading || isError || !!!intakeEntity) {
    return <></>;
  }

  const handleFlagStatusModal = (
    flagStatus: MigrationFlagStatus,
    isOpen: boolean
  ) => {
    setIsModalOpen(isOpen);
    setDirtyFlagStatus(flagStatus);
  };

  const migrationFlagStatusSelectOption =
    migrationFlagStatusSelectOptions.find(
      (option) => option.id === intakeEntity?.flagStatus
    ) || migrationFlagStatusSelectOptions[0];
  const migratedPercentage =
    intakeEntity.statistics.totalNumberOfFiles > 0 &&
    intakeEntity.statistics.filesSizeInMb > 0
      ? (intakeEntity.statistics.filesMigrated /
          intakeEntity.statistics.totalNumberOfFiles) *
        100
      : 0;

  return (
    <Stack maxHeight={"92vh"}>
      <Box
        sx={{
          width: "100%",
          my: 1,
          gap: 1,
          flexWrap: "wrap",
        }}
      >
        <Stack>
          <Typography level="h2">
            Intake {intakeEntity.intakeNumber}{" "}
            <MigrationStatusChip status={intakeEntity.status} size={"md"} />
          </Typography>
          <Stack
            width="100%"
            flex={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Stack
              flex={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Not Started</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.NotStarted]}
                >
                  {intakeEntity.statistics.totalNumberOfFiles -
                    (intakeEntity.statistics.filesMigrated +
                      intakeEntity.statistics.filesFlagged +
                      intakeEntity.statistics.filesInterrupted +
                      intakeEntity.statistics.filesNotFound)}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Migrated</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.Finished]}
                >
                  {intakeEntity.statistics.filesMigrated}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Interrupted</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.Interrupted]}
                >
                  {intakeEntity.statistics.filesInterrupted}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Not Found</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.NotFound]}
                >
                  {intakeEntity.statistics.filesNotFound}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Flagged</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.Flagged]}
                >
                  {intakeEntity.statistics.filesFlagged}
                </Chip>
              </FormControl>

              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Files Count</FormLabel>
                <Chip variant="soft" size="sm" color="neutral">
                  {intakeEntity.statistics.totalNumberOfFiles}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Size (MB)</FormLabel>
                <Chip variant="soft" size="sm" color="primary">
                  {intakeEntity.statistics.filesSizeInMb.toFixed(2)}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Progress</FormLabel>
                <Chip
                  sx={{ textAlign: "center" }}
                  variant="soft"
                  size="sm"
                  color={migratedPercentage > 99.99 ? "success" : "primary"}
                >
                  {migratedPercentage % 1 > 0.99 ||
                  migratedPercentage % 1 < 0.01
                    ? `${migratedPercentage.toFixed(0)}%`
                    : `${migratedPercentage.toFixed(2)}%`}
                </Chip>
              </FormControl>
            </Stack>

            {intakeEntity.flagType ? (
              <GenericSelect
                width={"260px"}
                size="md"
                options={migrationFlagStatusSelectOptions}
                placeholder="Select status"
                inputValue={migrationFlagStatusSelectOption}
                setValue={(value) => handleFlagStatusModal(value.id, true)}
              />
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
      </Box>
      <Grid container spacing={2} display={"inline-flex"} maxHeight={"100%"}>
        <Grid xs={12} lg={9} display={"flex"} maxHeight={"100%"}>
          <Card>
            <IntakeFilesTable
              organizationId={organizationId ?? ""}
              intakeId={intakeId ?? ""}
              activeJobsExplorer={selectedTab}
              newIntakeFilesForDeletion={newIntakeFilesForDeletion}
              setNewIntakesFilesForDeletion={setNewIntakeFilesForDeletion}
            />
          </Card>
        </Grid>
        <Grid
          xs={12}
          lg={3}
          display={"flex"}
          maxHeight={"100%"}
          height={"100%"}
        >
          <Card sx={{ width: "100%", padding: 0 }}>
            <Tabs
              value={selectedTab}
              sx={{
                maxHeight: "100%",
                borderRadius: "sm",
                overflow: "auto",
                height: "100%",
              }}
              onChange={(event, newValue) =>
                setSelectedTab(newValue as ActiveIntakeFilesJobsExplorer)
              }
            >
              <TabList
                disableUnderline
                tabFlex={1}
                sx={{
                  [`& .${tabClasses.root}`]: {
                    fontSize: "sm",
                    fontWeight: "md",
                    [`&[aria-selected="true"]`]: {
                      bgcolor: "background.surface",
                    },
                    [`&.${tabClasses.focusVisible}`]: {
                      outlineOffset: "-4px",
                    },
                  },
                }}
              >
                <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                  Delete files
                </Tab>
              </TabList>
              <TabPanel
                value={ActiveIntakeFilesJobsExplorer.DeleteIntakeFiles}
                sx={{
                  flex: 1,
                  overflow: "auto",
                  maxHeight: "100%",
                  height: "100%",
                  paddingTop: 0,
                }}
              >
                <Stack height={"100%"} spacing={2}>
                  {newIntakeFilesForDeletion.length > 0 && (
                    <>
                      <Divider sx={{ paddingTop: 2 }}>
                        <b>New</b>
                      </Divider>
                      <CreateDeleteIntakeFilesRequestTable
                        organizationId={organizationId ?? ""}
                        intakeId={intakeId ?? ""}
                        intakeNumber={intakeEntity.intakeNumber}
                        newFilesForDeletion={newIntakeFilesForDeletion}
                        setNewIntakeFilesForDeletion={
                          setNewIntakeFilesForDeletion
                        }
                      />
                      <Divider>
                        <b>Deletion status</b>
                      </Divider>
                    </>
                  )}
                  <Card
                    sx={{
                      padding: 0,
                      border: 0,
                      width: "100%",
                      height: "50%",
                    }}
                  >
                    <DeleteIntakeFilesStatusTable
                      organizationId={organizationId ?? ""}
                      intakeId={intakeId ?? ""}
                    />
                  </Card>
                </Stack>
              </TabPanel>
            </Tabs>
          </Card>
        </Grid>
      </Grid>
      {/* Flag Modal */}
      {dirtyFlagStatus && intakeEntity.flagType ? (
        <UpdateIntakeFlagModal
          open={isModalOpen}
          flagStatus={dirtyFlagStatus}
          intakeNumber={intakeEntity.intakeNumber}
          onClose={() => {
            handleFlagStatusModal(MigrationFlagStatus.UnResolved, false);
          }}
          onConfirm={async () => {
            if (intakeId !== undefined) {
              await intakesRepository.updateIntakeFlag(
                intakeEntity.id,
                dirtyFlagStatus
              );
              await queryClient.invalidateQueries(["cases", organizationId]);
              await queryClient.invalidateQueries(["case", intakeEntity.id]);
              handleFlagStatusModal(MigrationFlagStatus.UnResolved, false);
            }
          }}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default IntakePage;
