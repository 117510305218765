import React from "react";
import ConfirmationModal from "../common/ConfirmationModal";

interface ConfirmCaseFlagUpdateModalProps {
  open: boolean;
  caseNumber: string;
  isBlocked: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmCaseBlockUpdateModal: React.FC<ConfirmCaseFlagUpdateModalProps> = ({
  open,
  caseNumber,
  isBlocked,
  onClose,
  onConfirm,
}) => {
  const getMessage = (): string => {
    if (isBlocked) {
      return `Are you sure you want to block case ${caseNumber} migration?`;
    } else {
      return `Are you sure you want to unblock case ${caseNumber} migration?`;
    }
  };

  const getStatusColor = (): "warning" | "lime"  => {
    if (isBlocked) {
      return 'warning';
    } else {
      return 'lime';
    }
  };

  const getButtonText = (): string => {
    if (isBlocked) {
      return 'Block';
    } else {
      return 'Unblock';
    }
  };

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      message={getMessage()}
      statusColor={getStatusColor()}
      buttonText={getButtonText()}
    />
  );
};

export default ConfirmCaseBlockUpdateModal;
