/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/joy/Box";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { compareBy, Order, stableSort } from "../../helpers/TableHelpers";

import { ClearableSelect } from "../common/ClearableSelect";
import SortableTableColumnHeader from "../common/SortableTableColumnHeader";

import SearchIcon from "@mui/icons-material/Search";
import { Button, IconButton, Skeleton, useTheme } from "@mui/joy";
import JobRequestStatusChip from "../Jobs/JobRequestStatusChip";
import {
  JobRequestStatus,
  JobRequestStatusColors,
  JobRequestStatusLabels,
} from "../../models/Jobs/JobRequestStatus";
import CancelIcon from "@mui/icons-material/Cancel";
import { DeleteIntakeRequest, JobRequest } from "../../models/Jobs/JobRequest";
import AbortJobModal from "../Jobs/AbortJobModal";
import JobsRepository from "../../repositories/JobsRepository";
import Fuse from "fuse.js";
import { Delete } from "@mui/icons-material";
import ConfirmIntakeDeletionModal from "./ConfirmIntakesDeletionModal";
interface DeleteIntakesTableProps {
  organizationId: string;
  newIntakesForDeletion: DeleteIntakeRequest[];
  setNewIntakesForDeletion: React.Dispatch<
    React.SetStateAction<DeleteIntakeRequest[]>
  >;
}

const DeleteIntakesTable: React.FC<DeleteIntakesTableProps> = ({
  organizationId,
  newIntakesForDeletion,
  setNewIntakesForDeletion,
}) => {
  const theme = useTheme();
  const [order, setOrder] = React.useState<Order>("asc");
  const [sort, setSort] = React.useState<keyof JobRequest>("status");
  const [statusFilter, setStatusFilter] = React.useState<string | null>(null);
  const [searchForIntakeNumber, setSearchForIntakeNumber] = useState<
    string | null
  >("");
  const [abortJob, setAbortJob] = useState<JobRequest | null>(null);
  const [confirmDeletionModalOpen, setConfirmDeletionModalOpen] =
    useState<boolean>(false);
  const [intakesDeletionStatus, setIntakesDeletionStatus] = useState<
    DeleteIntakeRequest[] | null
  >(null);
  const jobsRepository = new JobsRepository();
  const queryClient = useQueryClient();
  const intakesQuery = useQuery(
    ["intakesDeletionStatus", organizationId],
    async () => {
      const intakes =
        await jobsRepository.getIntakesDeletionStatus(organizationId);
      return intakes;
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000 + 1, // Refetch data every 2 minutes
      refetchIntervalInBackground: true,
      refetchOnMount: true,
    }
  );
  useEffect(() => {
    if (intakesQuery.data) {
      setIntakesDeletionStatus(intakesQuery.data);
    }
  }, [intakesQuery]);
  const handleSearch = (newIntakeNumber: string) => {
    setSearchForIntakeNumber(newIntakeNumber ?? null);
  };

  const changeSort = (newSort: keyof JobRequest) => {
    setSort(newSort);
  };
  const changeOrder = (newOrder: Order) => {
    setOrder(newOrder);
  };

  const { isError, isFetching } = intakesQuery;
  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Status</FormLabel>
        <ClearableSelect
          value={statusFilter}
          color={JobRequestStatusColors[statusFilter as JobRequestStatus]}
          onChange={(newValue) => {
            setStatusFilter(newValue);
          }}
          size="sm"
          placeholder="Filter by status"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
        >
          {Object.values(JobRequestStatus).map((status, idx) => {
            const deleteRequestStatus = status as JobRequestStatus;
            return (
              <Option
                key={idx}
                value={deleteRequestStatus}
                color={JobRequestStatusColors[deleteRequestStatus]}
              >
                {JobRequestStatusLabels[deleteRequestStatus]}
              </Option>
            );
          })}
        </ClearableSelect>
      </FormControl>
    </React.Fragment>
  );
  const filteredJobs = (intakesDeletionStatus || []).filter((job) => {
    const statusMatches = statusFilter == null || job.status === statusFilter;
    return statusMatches;
  });
  const searchOptions = {
    keys: ["intakeNumber"],
    includeScore: true,
    threshold: 0.1,
    caseSensitive: false,
  };
  const fuse = new Fuse(filteredJobs, searchOptions);
  const searchResults = searchForIntakeNumber
    ? fuse.search(searchForIntakeNumber).map((result) => result.item)
    : filteredJobs;
  const sortedJobs = sort
    ? stableSort<DeleteIntakeRequest>(searchResults, compareBy(order, sort))
    : filteredJobs;
  const allRows = [...newIntakesForDeletion, ...sortedJobs];
  const newIds = new Set(newIntakesForDeletion.map((c) => c.migrationEntityId));

  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for intake</FormLabel>
          <Input
            size="sm"
            placeholder="Intake number"
            startDecorator={<SearchIcon />}
            onChange={(event) => handleSearch(event.target.value)}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Button
        disabled={!(newIntakesForDeletion.length > 0)}
        onClick={() => setConfirmDeletionModalOpen(true)}
      >
        Delete Intakes
      </Button>
      <Sheet
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Skeleton variant="inline" loading={isFetching || isError}>
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            stickyFooter
            hoverRow
            sx={{
              "--TableCell-headBackground":
                "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground":
                "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: "5%",
                    minWidth: 48,
                    textAlign: "center",
                    padding: "12px 6px",
                  }}
                ></th>

                <th
                  style={{
                    width: "40%",
                    padding: "12px 6px",
                  }}
                >
                  <SortableTableColumnHeader
                    label={"Intake Number"}
                    sortProperty={"intakeNumber"}
                    getSort={() => sort}
                    setSort={(newSort) =>
                      changeSort(newSort as keyof JobRequest)
                    }
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>

                <th style={{ width: "35%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Status"}
                    sortProperty={"status"}
                    getSort={() => sort}
                    setSort={(newSort) =>
                      changeSort(newSort as keyof JobRequest)
                    }
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {allRows.map((deleteIntakeJob) => {
                const canEdit =
                  deleteIntakeJob.status === JobRequestStatus.Pending ||
                  deleteIntakeJob.status === JobRequestStatus.New ||
                  deleteIntakeJob.status === JobRequestStatus.Completed;
                return (
                  <tr
                    key={deleteIntakeJob.migrationEntityId}
                    style={{
                      backgroundColor: newIds.has(
                        deleteIntakeJob.migrationEntityId
                      )
                        ? theme.palette.primary.plainHoverBg
                        : "inherit",
                    }}
                  >
                    <td style={{ textAlign: "center", width: 120 }}></td>
                    <td>
                      <Typography level="body-sm">
                        {deleteIntakeJob.intakeNumber}
                      </Typography>
                    </td>
                    <td>
                      <JobRequestStatusChip status={deleteIntakeJob.status} />
                    </td>
                    {canEdit ? (
                      <td>
                        <IconButton
                          aria-label="Cancel"
                          size="sm"
                          color="danger"
                          onClick={() => {
                            deleteIntakeJob.id
                              ? setAbortJob(deleteIntakeJob)
                              : setNewIntakesForDeletion((prevRows) =>
                                  prevRows.filter(
                                    (entity) =>
                                      entity.migrationEntityId !==
                                      deleteIntakeJob.migrationEntityId
                                  )
                                );
                          }}
                        >
                          {deleteIntakeJob.status ===
                          JobRequestStatus.Completed ? (
                            <Delete />
                          ) : (
                            <CancelIcon />
                          )}{" "}
                        </IconButton>
                      </td>
                    ) : (
                      <td />
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Skeleton>
      </Sheet>
      {abortJob ? (
        <AbortJobModal
          open={abortJob !== null}
          jobRequest={abortJob}
          onClose={() => {
            setAbortJob(null);
          }}
          onConfirm={async () => {
            await jobsRepository.deleteJob(abortJob.id);
            queryClient.invalidateQueries("jobs");
            queryClient.invalidateQueries([
              "intakesDeletionStatus",
              organizationId,
            ]);
            if (abortJob.status !== JobRequestStatus.Completed) {
              queryClient.invalidateQueries(["intakes", organizationId]);
            }
            setAbortJob(null);
          }}
        />
      ) : (
        <></>
      )}
      {confirmDeletionModalOpen ? (
        <ConfirmIntakeDeletionModal
          open={confirmDeletionModalOpen}
          intakeNumbers={newIntakesForDeletion.map((c) => c.intakeNumber)}
          onClose={() => {
            setConfirmDeletionModalOpen(false);
          }}
          onConfirm={async () => {
            await jobsRepository.startIntakesDeletion(
              organizationId,
              newIntakesForDeletion.map((c) => c.migrationEntityId)
            );
            queryClient.invalidateQueries("jobs");
            queryClient.invalidateQueries([
              "intakeDeletionStatus",
              organizationId,
            ]);
            queryClient.invalidateQueries(["intakes", organizationId]);
            setNewIntakesForDeletion([]);
            setConfirmDeletionModalOpen(false);
          }}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default DeleteIntakesTable;
