import { Divider, Modal, ModalDialog } from "@mui/joy";
import Button from "@mui/joy/Button";
import DialogActions from '@mui/joy/DialogActions';
import DialogContent from '@mui/joy/DialogContent';
import DialogTitle from '@mui/joy/DialogTitle';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import React from "react";

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
  statusColor: "primary" | "neutral" | "danger" | "success" | "warning" | "pink" | "lime" | "yellow";
  buttonText: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
  message,
  statusColor,
  buttonText,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle>
          <WarningRoundedIcon />
          Confirmation required
        </DialogTitle>
        <Divider />
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <Button
            variant="solid"
            onClick={onConfirm}
            color={statusColor}
          >
            {buttonText}
          </Button>
          <Button
            variant="plain"
            color="neutral"
            onClick={onClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default ConfirmationModal;
