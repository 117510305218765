import { AxiosResponse } from "axios";
import { Paging } from "../models/Paging";
import useApi from "../services/ApiService";
import { MigrationFlagStatus } from "../models/Flags/MigrationFlagStatus";
import { IntakeFileFilters } from "../models/IntakeFileFilters";
import { Intake } from "../models/Intake";
import { IntakeFile } from "../models/IntakeFile";
import { saveAs } from "file-saver";

interface GetIntakesQueryOptions {
  organizationId?: string;
  status?: string[] | null;
  createdBy?: string[];
  intakeNumber?: string[] | null;
  ids?: string[];
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  descending?: boolean;
}
interface GetIntakeFilesForIntakeQueryOptions {
  status?: string[] | null;
  extension?: string[] | null;
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  descending?: boolean;
  nestedFolder1?: string | null;
  nestedFolder2?: string | null;
  filePath?: string | null;
  minFileSize?: number | null;
  maxFileSize?: number | null;
  type?: number | null;
}

export interface FileWithName {
  file: any;
  name: string;
}

export class IntakesRepository {
  private api = useApi();

  async getIntakes(
    options: GetIntakesQueryOptions = {}
  ): Promise<{ data: Intake[]; paging: Paging | null }> {
    const {
      status = null,
      createdBy = null,
      intakeNumber = null,
      ids = null,
      pageNumber = null,
      pageSize = null,
      orderBy = null,
      descending = true,
    } = options;

    try {
      const response = await this.api.get("/api/Intakes", {
        params: {
          Status: status,
          CreatedBy: createdBy,
          IntakeNumber: intakeNumber,
          Id: ids,
          PageNumber: pageNumber,
          PageSize: pageSize,
          OrderBy: orderBy,
          Descending: descending,
        },
        paramsSerializer: {
          indexes: true,
        },
      });

      const { data, headers } = response;
      let paging: Paging | null = null;
      const paginationHeader = headers["x-pagination"];
      if (paginationHeader) {
        paging = JSON.parse(paginationHeader || "");
      }

      return { data, paging };
    } catch (error) {
      // Handle error
      throw new Error("Failed to fetch intakes");
    }
  }

  async getIntake(id: string): Promise<Intake> {
    try {
      const response = await this.api.get(`/api/Intakes/${id}`);
      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(`Failed to fetch intake with id: ${id}`);
    }
  }

  async getIntakeFilesForIntake(
    id: string,
    options: GetIntakeFilesForIntakeQueryOptions = {}
  ): Promise<{ data: IntakeFile[]; paging: Paging | null }> {
    const {
      status = null,
      extension = null,
      pageNumber = null,
      pageSize = null,
      orderBy = null,
      descending = true,
      nestedFolder1 = null,
      nestedFolder2 = null,
      filePath = null,
      minFileSize = null,
      maxFileSize = null,
      type = null,
    } = options;
    try {
      const response = await this.api.get(`/api/Intakes/${id}/files`, {
        params: {
          Status: status,
          Extension: extension,
          NestedFolder1: nestedFolder1,
          NestedFolder2: nestedFolder2,
          FilePath: filePath,
          MinFileSize: minFileSize,
          MaxFileSize: maxFileSize,
          PageNumber: pageNumber,
          PageSize: pageSize,
          OrderBy: orderBy,
          Descending: descending,
          Type: type,
        },
        paramsSerializer: {
          indexes: true,
        },
        timeout: 100 * 1000,
      });

      const { data, headers } = response;
      let paging: Paging | null = null;
      const paginationHeader = headers["x-pagination"];
      if (paginationHeader) {
        paging = JSON.parse(paginationHeader || "");
      }
      return { data, paging };
    } catch (error) {
      // Handle error
      throw new Error(`Failed to fetch intake with id: ${id}`);
    }
  }

  async getIntakeFilesFiltersForIntake(
    id: string
  ): Promise<{ data: IntakeFileFilters }> {
    try {
      const response = await this.api.get(`/api/Intakes/${id}/files/filters`, {
        timeout: 100 * 1000,
      });
      return { data: response.data };
    } catch (error) {
      // Handle error
      throw new Error(
        `Failed to fetch intake filters for intake with id: ${id}`
      );
    }
  }

  async updateIntakeFlag(
    intakeId: string,
    flagStatus: MigrationFlagStatus
  ): Promise<void> {
    try {
      const data: Record<string, any> = {
        intakeId: intakeId,
        flagStatus: flagStatus,
      };

      const response: AxiosResponse<void> = await this.api.patch(
        `/api/Intakes/updateFlag`,
        data
      );

      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(
        `Failed to update flag info of intake with id: ${intakeId}`
      );
    }
  }

  async updateIntakeBlock(intakeId: string, block: boolean): Promise<void> {
    try {
      const data: Record<string, any> = {
        entityId: intakeId,
        block: block,
      };

      const response: AxiosResponse<void> = await this.api.patch(
        `/api/Intakes/updateBlock`,
        data
      );

      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(
        `Failed to update block info of intake with id: ${intakeId}`
      );
    }
  }

  async downloadSharepointFile(
    organizationId: string,
    intakeId: string,
    IntakeFileId: string
  ): Promise<void> {
    try {
      const data: Record<string, any> = {
        organizationId: organizationId,
        intakeId: intakeId,
        IntakeFileId: IntakeFileId,
      };

      const response: AxiosResponse<any> = await this.api.post(
        `/api/Intakes/sharepoint/download`,
        data
      );
      saveAs(new Blob([response.data.file]), response.data.name);
    } catch (error) {
      throw new Error(`Failed to download file for intakeId: ${intakeId} and intakeFileId: ${IntakeFileId}`);
    }
  }
}

export default IntakesRepository;
