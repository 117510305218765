export enum AuditLogType {
  ClientConfigurationChange = "clientConfigurationChange",
  OrganizationConfigurationChange = "organizationConfigurationChange",
  CasesExport = "casesExport",
  CaseFilesExport = "caseFilesExport",
  IntakesExport = "intakesExport",
  IntakeFilesExport = "intakeFilesExport"
}

export const AuditLogTypeLabels: Record<AuditLogType, string> = {
  [AuditLogType.ClientConfigurationChange]: "Client Configuration Change",
  [AuditLogType.OrganizationConfigurationChange]: "Organization Configuration Change",
  [AuditLogType.CasesExport]: "Cases Export",
  [AuditLogType.CaseFilesExport]: "Case Files Export",
  [AuditLogType.IntakesExport]: "Intakes Export",
  [AuditLogType.IntakeFilesExport]: "Intake Files Export",
};

export interface AuditLog {
  id: string;
  entityId: string | null;
  eventType: AuditLogType;
  oldValue: string | null;
  newValue: string | null;
  additionalData: string | null;
  performedBy: string;
  eventDate: string;
  performerEmailAddress: string;
}