/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/joy/Box";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { compareBy, Order, stableSort } from "../../helpers/TableHelpers";

import { ClearableSelect } from "../common/ClearableSelect";
import SortableTableColumnHeader from "../common/SortableTableColumnHeader";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, Select, Skeleton } from "@mui/joy";
import {
  JobRequestStatus,
  JobRequestStatusColors,
  JobRequestStatusLabels,
} from "../../models/Jobs/JobRequestStatus";
import JobsRepository from "../../repositories/JobsRepository";
import { isDeleteCaseRequest, JobRequest } from "../../models/Jobs/JobRequest";
import JobRequestStatusChip from "./JobRequestStatusChip";
import JobTypeChip from "./JobTypeChip";
import JobEntityTypeChip from "./JobEntityTypeChip";
import JobInfoView from "./JobInfoView";
import CancelIcon from "@mui/icons-material/Cancel";
import AbortJobModal from "./AbortJobModal";
import {
  JobEntityType,
  JobEntityTypeColors,
  JobEntityTypeLabels,
} from "../../models/Jobs/JobEntityType";
import {
  JobType,
  JobTypeColors,
  JobTypeLabels,
} from "../../models/Jobs/JobType";
import Fuse from "fuse.js";
import { Delete } from "@mui/icons-material";

const JobsTable = () => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [sort, setSort] = React.useState<keyof JobRequest>("status");
  const [organizationFilter, setOrganizationFilter] = React.useState<
    string | null
  >(null);
  const [jobTypeFilter, setJobTypeFilter] = React.useState<string | null>(null);
  const [statusFilter, setStatusFilter] = React.useState<string | null>(null);
  const [entityTypeFilter, setEntityTypeFilter] = React.useState<string | null>(
    null
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [jobs, setJobs] = useState<JobRequest[]>([]);
  const [abortJob, setAbortJob] = useState<JobRequest | null>(null);

  const jobsRepository = new JobsRepository();
  const queryClient = useQueryClient();
  const jobsQuery = useQuery(
    ["jobs"],
    async () => {
      return await jobsRepository.getJobs();
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000 + 1, // Refetch data every 2 minutes
      refetchIntervalInBackground: true,
      refetchOnMount: true,
    }
  );
  useEffect(() => {
    if (jobsQuery.data) {
      setJobs(jobsQuery.data);
    }
  }, [jobsQuery]);
  const getQueryInvalidationKeyForJob = (job: JobRequest) => {
    if (isDeleteCaseRequest(job)) {
      const keys = ["casesDeletionStatus"];
      if (job.status !== JobRequestStatus.Completed) {
        keys.push("cases");
      }
      return keys;
    }
  };
  const handleSearch = (newSearchQuery: string) => {
    setSearchQuery(newSearchQuery ?? null);
  };
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };
  const changeSort = (newSort: keyof JobRequest) => {
    setPage(0);
    setSort(newSort);
  };
  const changeOrder = (newOrder: Order) => {
    setPage(0);
    setOrder(newOrder);
  };
  const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };

  const getLabelDisplayedRowsTo = (rows: number) => {
    if (rows === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1 ? rows : Math.min(rows, (page + 1) * rowsPerPage);
  };
  function labelDisplayedRows({
    from,
    to,
    count,
  }: {
    from: number;
    to: number;
    count: number;
  }) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
  }
  const { isError, isFetching } = jobsQuery;
  const organizations = Array.from(
    new Set(jobs?.map((job) => job.organizationName))
  ).sort();
  const filteredStatusOptions = Object.values(JobRequestStatus).filter(
    (status) => status !== JobRequestStatus.New
  );
  const renderFilters = () => (
    <React.Fragment>
      {organizations && (
        <FormControl size="sm" sx={{ minWidth: "25%" }}>
          <FormLabel>Organization</FormLabel>
          <ClearableSelect
            value={organizationFilter}
            onChange={(newValue) => {
              setPage(0);
              setOrganizationFilter(newValue);
            }}
            size="sm"
            placeholder="Filter by organization"
            slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
          >
            {organizations.map((organization, idx) => {
              return (
                <Option key={idx} value={organization}>
                  {organization}
                </Option>
              );
            })}
          </ClearableSelect>
        </FormControl>
      )}
      <FormControl size="sm">
        <FormLabel>Job Type</FormLabel>
        <ClearableSelect
          value={jobTypeFilter}
          color={JobTypeColors[jobTypeFilter as JobType]}
          onChange={(newValue) => {
            setPage(0);
            setJobTypeFilter(newValue);
          }}
          size="sm"
          placeholder="Filter by job type"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
        >
          {Object.values(JobType).map((type, idx) => {
            const jobType = type as JobType;
            return (
              <Option key={idx} value={jobType} color={JobTypeColors[jobType]}>
                {JobTypeLabels[jobType]}
              </Option>
            );
          })}
        </ClearableSelect>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Status</FormLabel>
        <ClearableSelect
          value={statusFilter}
          color={JobRequestStatusColors[statusFilter as JobRequestStatus]}
          onChange={(newValue) => {
            setPage(0);
            setStatusFilter(newValue);
          }}
          size="sm"
          placeholder="Filter by status"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
        >
          {filteredStatusOptions.map((status, idx) => {
            const jobRequestStatus = status as JobRequestStatus;
            return (
              <Option
                key={idx}
                value={jobRequestStatus}
                color={JobRequestStatusColors[jobRequestStatus]}
              >
                {JobRequestStatusLabels[jobRequestStatus]}
              </Option>
            );
          })}
        </ClearableSelect>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Entity Type</FormLabel>
        <ClearableSelect
          value={entityTypeFilter}
          color={JobEntityTypeColors[entityTypeFilter as JobEntityType]}
          onChange={(newValue) => {
            setPage(0);
            setEntityTypeFilter(newValue);
          }}
          size="sm"
          placeholder="Filter by entity type"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
        >
          {Object.values(JobEntityType).map((jobEntityType, idx) => {
            const entityType = jobEntityType as JobEntityType;
            return (
              <Option
                key={idx}
                value={entityType}
                color={JobEntityTypeColors[entityType]}
              >
                {JobEntityTypeLabels[entityType]}
              </Option>
            );
          })}
        </ClearableSelect>
      </FormControl>
    </React.Fragment>
  );

  const filteredJobs = jobs.filter((job) => {
    const statusMatches =
      statusFilter == null || job.status === (statusFilter as JobRequestStatus);
    const entityTypeMatches =
      entityTypeFilter == null ||
      job.entityType === (entityTypeFilter as JobEntityType);
    const jobTypeMatches =
      jobTypeFilter == null || job.type === (jobTypeFilter as JobType);
    const organizationMatches =
      organizationFilter == null || job.organizationName === organizationFilter;
    return (
      statusMatches &&
      entityTypeMatches &&
      jobTypeMatches &&
      organizationMatches
    );
  });
  const searchOptions = {
    keys: [
      "caseNumber",
      "organizationId",
      "type",
      "status",
      "entityType",
      "migrationEntityId",
      "organizationName",
      "exportName",
    ],
    includeScore: true,
    threshold: 0.1,
    caseSensitive: false,
  };

  const fuse = new Fuse(filteredJobs, searchOptions);
  const searchResults = searchQuery
    ? fuse.search(searchQuery).map((result) => result.item)
    : filteredJobs;
  const sortedJobs = sort
    ? stableSort<JobRequest>(searchResults, compareBy(order, sort))
    : filteredJobs;
  const paginatedJobs = sortedJobs.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search jobs</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            startDecorator={<SearchIcon />}
            onChange={(event) => handleSearch(event.target.value)}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Skeleton variant="inline" loading={isFetching || isError}>
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            stickyFooter
            hoverRow
            sx={{
              "--TableCell-headBackground":
                "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground":
                "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: "5%",
                    textAlign: "center",
                    padding: "12px 6px",
                  }}
                ></th>

                <th style={{ width: "20%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Organization"}
                    sortProperty={"organizationName"}
                    getSort={() => sort}
                    setSort={(newSort) =>
                      changeSort(newSort as keyof JobRequest)
                    }
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>
                <th
                  style={{
                    width: "10%",
                    padding: "12px 6px",
                  }}
                >
                  <SortableTableColumnHeader
                    label={"Job Type"}
                    sortProperty={"type"}
                    getSort={() => sort}
                    setSort={(newSort) =>
                      changeSort(newSort as keyof JobRequest)
                    }
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>
                <th style={{ width: "10%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Entity Type"}
                    sortProperty={"entityType"}
                    getSort={() => sort}
                    setSort={(newSort) =>
                      changeSort(newSort as keyof JobRequest)
                    }
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>
                <th style={{ width: "10%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Status"}
                    sortProperty={"status"}
                    getSort={() => sort}
                    setSort={(newSort) =>
                      changeSort(newSort as keyof JobRequest)
                    }
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>

                <th style={{ width: "40%", padding: "12px 6px" }}>Info</th>

                <th
                  style={{
                    width: "5%",
                    minWidth: 48,
                    textAlign: "center",
                    padding: "12px 6px",
                  }}
                ></th>
              </tr>
            </thead>

            <tbody>
              {paginatedJobs.map((job) => {
                const canAbort =
                  job.status === JobRequestStatus.Pending ||
                  job.status === JobRequestStatus.Completed;
                return (
                  <tr key={job.id}>
                    <td style={{ textAlign: "center" }}></td>
                    <td>
                      <Typography level="body-sm">
                        {job.organizationName}
                      </Typography>
                    </td>
                    <td>
                      <JobTypeChip type={job.type} />
                    </td>
                    <td>
                      <JobEntityTypeChip type={job.entityType} />
                    </td>
                    <td>
                      <JobRequestStatusChip status={job.status} />
                    </td>
                    <td>
                      <JobInfoView jobRequest={job} />
                    </td>

                    <td>
                      {canAbort && (
                        <IconButton
                          aria-label="Cancel"
                          size="sm"
                          color="danger"
                          onClick={async () => {
                            setAbortJob(job);
                          }}
                        >
                          {job.status === JobRequestStatus.Completed ? (
                            <Delete />
                          ) : (
                            <CancelIcon />
                          )}
                        </IconButton>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>

            <tfoot>
              <tr>
                <td colSpan={9}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      justifyContent: "flex-end",
                    }}
                  >
                    <FormControl orientation="horizontal" size="sm">
                      <FormLabel>Rows per page:</FormLabel>
                      <Select
                        onChange={handleChangeRowsPerPage}
                        value={rowsPerPage}
                      >
                        <Option value={25}>25</Option>
                        <Option value={50}>50</Option>
                        <Option value={100}>100</Option>
                      </Select>
                    </FormControl>

                    <Typography textAlign="center" sx={{ minWidth: 80 }}>
                      {labelDisplayedRows({
                        from:
                          sortedJobs.length === 0 ? 0 : page * rowsPerPage + 1,
                        to: getLabelDisplayedRowsTo(sortedJobs.length),
                        count: sortedJobs.length,
                      })}
                    </Typography>

                    <Box sx={{ display: "flex", gap: 1 }}>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={page === 0}
                        onClick={() => handleChangePage(page - 1)}
                        sx={{ bgcolor: "background.surface" }}
                      >
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={
                          sortedJobs.length !== -1
                            ? page >=
                              Math.ceil(sortedJobs.length / rowsPerPage) - 1
                            : false
                        }
                        onClick={() => handleChangePage(page + 1)}
                        sx={{ bgcolor: "background.surface" }}
                      >
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </td>
              </tr>
            </tfoot>
          </Table>
        </Skeleton>
      </Sheet>
      {abortJob ? (
        <AbortJobModal
          open={abortJob !== null}
          jobRequest={abortJob}
          onClose={() => {
            setAbortJob(null);
          }}
          onConfirm={async () => {
            await jobsRepository.deleteJob(abortJob.id);
            queryClient.invalidateQueries("jobs");
            const keys = getQueryInvalidationKeyForJob(abortJob);
            if (keys) {
              keys.forEach((key) =>
                queryClient.invalidateQueries([key, abortJob.organizationId])
              );
            }
            setAbortJob(null);
          }}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default JobsTable;
