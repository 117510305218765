import { ColorPaletteProp } from "@mui/joy";

export interface FlaggingConfiguration {
  flagType: FlagType;
  flagProperties: FlagProperty[];
}

export enum FlagType {
  MigrationEntityWithMoreThan10KFiles = "migrationEntityWithMoreThan10KFiles",
  DicomFile = "dicomFile",
  FileExtension = "fileExtension",
  MigrationEntityBlockedByUser = "migrationEntityBlockedByUser",
}

export const FlagTypeLabels: Record<FlagType, string> = {
  [FlagType.MigrationEntityWithMoreThan10KFiles]: "Cases with more than 10k files",
  [FlagType.DicomFile]: "DICOM case files",
  [FlagType.FileExtension]: "Flag case files with extension:",
  [FlagType.MigrationEntityBlockedByUser]: "Cases blocked by user",
};

export const FlagTypeChipLabels: Record<FlagType, string> = {
  [FlagType.MigrationEntityWithMoreThan10KFiles]: "> 10K Files",
  [FlagType.DicomFile]: "DICOM",
  [FlagType.FileExtension]: "Extension",
  [FlagType.MigrationEntityBlockedByUser]: "Blocked By User",
};

export const FlagTypeChipColors: Record<FlagType, ColorPaletteProp> = {
  [FlagType.MigrationEntityWithMoreThan10KFiles]: "danger",
  [FlagType.DicomFile]: "neutral",
  [FlagType.FileExtension]: "primary",
  [FlagType.MigrationEntityBlockedByUser]: "warning",
};

export interface FlagProperty {
  key: string;
  value: string;
}
