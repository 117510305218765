/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Order } from "../../helpers/TableHelpers";
import { Intake } from "../../models/Intake";
import {
  MigrationStatus,
  MigrationStatusColors,
  MigrationStatusLabels,
} from "../../models/MigrationStatus";
import { FlagType } from "../../models/ClientConfiguration/FlaggingConfiguration";
import { ClearableSelect } from "../common/ClearableSelect";
import MigrationStatusChip from "../common/MigrationStatusChip";
import SortableTableColumnHeader from "../common/SortableTableColumnHeader";
import ConfirmIntakeFlagUpdateModal from "./ConfirmIntakeFlagUpdateModal";
import ConfirmIntakeBlockUpdateModal from "./ConfirmIntakeBlockUpdateModal";
import GenericSelect from "../common/GenericSelect";
import {
  MigrationFlagStatus,
  migrationFlagStatusSelectOptions,
} from "../../models/Flags/MigrationFlagStatus";
import { Button, IconButton, Select, Skeleton } from "@mui/joy";
import { JobRequestStatus } from "../../models/Jobs/JobRequestStatus";
import { DeleteIntakeRequest } from "../../models/Jobs/JobRequest";
import { JobType } from "../../models/Jobs/JobType";
import { JobEntityType } from "../../models/Jobs/JobEntityType";
import FlagTypeChip from "../common/FlagTypeChip";

// icons
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import TableViewIcon from "@mui/icons-material/TableView";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import BlockIcon from "@mui/icons-material/Block";
import IntakesRepository from "../../repositories/IntakesRepository";
import { ActiveIntakeJobsExplorer } from "../../pages/IntakesPage";

interface IntakesTableProps {
  organizationId: string;
  activeJobsExplorer: ActiveIntakeJobsExplorer;
  newIntakesForDeletion: DeleteIntakeRequest[];
  setNewIntakesForDeletion: React.Dispatch<
    React.SetStateAction<DeleteIntakeRequest[]>
  >;
  newIntakesForExport: Intake[];
  setNewIntakesForExport: React.Dispatch<React.SetStateAction<Intake[]>>;
}

const IntakesTable: React.FC<IntakesTableProps> = ({
  organizationId,
  activeJobsExplorer,
  newIntakesForDeletion,
  setNewIntakesForDeletion,
  newIntakesForExport,
  setNewIntakesForExport,
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [sort, setSort] = React.useState<string>("status");
  const [statusFilter, setStatusFilter] = React.useState<string | null>(null);
  const [searchForIntakeNumber, setSearchForIntakeNumber] = useState<
    string | null
  >("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [intakes, setIntakes] = useState<Intake[]>([]);
  const [rows, setRows] = useState(0);
  const [isFlagModalOpen, setIsFlagModalOpen] = React.useState<boolean>(false);
  const [dirtyFlagStatus, setDirtyFlagStatus] = useState<MigrationFlagStatus>();
  const [dirtyIntakeId, setDirtyIntakeId] = useState<string>("");
  const [dirtyIntakeNumber, setDirtyIntakeNumber] = useState<string>("");
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [isBlockModalOpen, setIsBlockModalOpen] =
    React.useState<boolean>(false);

  const intakesRepository = new IntakesRepository();
  const navigate = useNavigate();
  const intakesQuery = useQuery(
    [
      "intakes",
      organizationId,
      searchForIntakeNumber,
      page,
      rowsPerPage,
      statusFilter,
      sort,
      order,
    ],
    async () => {
      const intakes = await intakesRepository.getIntakes({
        organizationId: organizationId,
        intakeNumber: searchForIntakeNumber ? [searchForIntakeNumber] : null,
        descending: order === "desc",
        orderBy: sort,
        status: statusFilter ? [statusFilter] : null,
        pageNumber: page + 1,
        pageSize: rowsPerPage,
      });
      return intakes;
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000 + 1, // Refetch data every 2 minutes
      refetchIntervalInBackground: true,
      refetchOnMount: true,
    }
  );

  useEffect(() => {
    if (intakesQuery.data?.data) {
      setIntakes(intakesQuery.data?.data);
      setRows(intakesQuery.data.paging?.totalCount ?? 0);
    }
  }, [intakesQuery]);
  const handleSearch = (newIntakeNumber: string) => {
    setSearchForIntakeNumber(newIntakeNumber ?? null);
  };
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };
  const changeSort = (newSort: string) => {
    setPage(0);
    setSort(newSort);
  };
  const changeOrder = (newOrder: Order) => {
    setPage(0);
    setOrder(newOrder);
  };
  const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };
  const handleDelete = (intakeId: string, intakeNumber: string) => {
    if (!disabledRows.has(intakeId)) {
      setNewIntakesForDeletion((prevRows) => [
        ...prevRows,
        {
          migrationEntityId: intakeId,
          intakeNumber: intakeNumber,
          status: JobRequestStatus.New,
            type: JobType.Delete,
          entityType: JobEntityType.Intake,
          organizationId: organizationId,
        } as DeleteIntakeRequest,
      ]);
    }
  };

  const handleAddIntakeForExport = (intakeEntity: Intake) => {
    if (!disabledRows.has(intakeEntity.id)) {
      setNewIntakesForExport((prevRows) => [...prevRows, intakeEntity]);
    }
  };

  const getLabelDisplayedRowsTo = () => {
    if (rows === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1 ? rows : Math.min(rows, (page + 1) * rowsPerPage);
  };

  const getDisabledRows = () => {
    switch (activeJobsExplorer) {
      case ActiveIntakeJobsExplorer.DeleteIntakes:
        return new Set(newIntakesForDeletion.map((c) => c.migrationEntityId));
      case ActiveIntakeJobsExplorer.GenerateCSV:
        return new Set(newIntakesForExport.map((c) => c.id));
    }
  };

  function labelDisplayedRows({
    from,
    to,
    count,
  }: {
    from: number;
    to: number;
    count: number;
  }) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
  }

  const getActiveButtonForExplorer = (
    intakeEntity: Intake,
    isDisabled: boolean
  ) => {
    switch (activeJobsExplorer) {
      case ActiveIntakeJobsExplorer.DeleteIntakes:
        return (
          <IconButton
            aria-label="Delete"
            disabled={isDisabled}
            size="sm"
            color="danger"
            onClick={() =>
              handleDelete(intakeEntity.id, intakeEntity.intakeNumber)
            }
          >
            <DeleteIcon />
          </IconButton>
        );
      case ActiveIntakeJobsExplorer.GenerateCSV:
        return (
          <IconButton
            aria-label="Csv"
            disabled={isDisabled}
            size="sm"
            color="success"
            onClick={() => handleAddIntakeForExport(intakeEntity)}
          >
            <TableViewIcon />
          </IconButton>
        );
      default:
        <></>;
    }
  };

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Status</FormLabel>
        <ClearableSelect
          value={statusFilter}
          color={MigrationStatusColors[statusFilter as MigrationStatus]}
          onChange={(newValue) => {
            setPage(0);
            setStatusFilter(newValue);
          }}
          size="sm"
          placeholder="Filter by status"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
        >
          {Object.values(MigrationStatus)
            .filter((v) => v !== MigrationStatus.NotFound)
            .map((status, idx) => {
              const migrationStatus = status as MigrationStatus;
              return (
                <Option
                  key={idx}
                  value={migrationStatus}
                  color={MigrationStatusColors[migrationStatus]}
                >
                  {MigrationStatusLabels[migrationStatus]}
                </Option>
              );
            })}
        </ClearableSelect>
      </FormControl>
    </React.Fragment>
  );

  const handleFlagStatusModal = (
    intakeNumber: string,
    intakeId: string,
    flagStatus: MigrationFlagStatus,
    isOpen: boolean
  ) => {
    setIsFlagModalOpen(isOpen);
    setDirtyIntakeNumber(intakeNumber);
    setDirtyIntakeId(intakeId);
    setDirtyFlagStatus(flagStatus);
  };

  const handleBlockModal = (
    intakeNumber: string,
    intakeId: string,
    isBlocked: boolean,
    isOpen: boolean
  ) => {
    setIsBlockModalOpen(isOpen);
    setDirtyIntakeNumber(intakeNumber);
    setDirtyIntakeId(intakeId);
    setIsBlocked(isBlocked);
  };

  const { isError, isFetching } = intakesQuery;
  const disabledRows = getDisabledRows();

  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          pb: 0.5,
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for intake</FormLabel>
          <Input
            size="sm"
            placeholder="Intake number"
            startDecorator={<SearchIcon />}
            onBlur={(event) => handleSearch(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                const target = event.target as HTMLInputElement;
                target.blur();
              }
            }}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="IntakesTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Skeleton variant="inline" loading={isFetching || isError}>
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            stickyFooter
            hoverRow
            sx={{
              "--TableCell-headBackground":
                "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground":
                "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: "1.5%",
                    minWidth: 48,
                    textAlign: "center",
                    padding: "12px 6px",
                  }}
                ></th>

                <th
                  style={{
                    width: "18%",
                    padding: "12px 6px",
                  }}
                >
                  <SortableTableColumnHeader
                    label={"Number"}
                    sortProperty={"intakeNumber"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>

                <th style={{ width: "14%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Status"}
                    sortProperty={"status"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>

                <th style={{ width: "10%", padding: "12px 6px" }}>Progress</th>

                <th style={{ width: "12%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Files"}
                    sortProperty={"totalNumberOfFiles"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>

                <th style={{ width: "12%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Size"}
                    sortProperty={"totalFilesSize"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>

                <th style={{ width: "18%", padding: "12px 6px" }}>Flag Type</th>

                <th style={{ width: "18%", padding: "12px 6px" }}>
                  Blocked By
                </th>

                <th style={{ width: "20%", padding: "12px 6px" }}>
                  Flag Status
                </th>

                <th
                  style={{
                    width: "10%",
                    maxWidth: 10,
                    padding: "12px 6px",
                  }}
                ></th>
              </tr>
            </thead>

            <tbody>
              {intakes.map((intakeEntity) => {
                const migratedPercentage =
                  intakeEntity.statistics.totalNumberOfFiles > 0
                    ? (intakeEntity.statistics.filesMigrated /
                        intakeEntity.statistics.totalNumberOfFiles) *
                      100
                    : -1;

                const migrationFlagStatusSelectOption =
                  migrationFlagStatusSelectOptions.find(
                    (option) => option.id === intakeEntity?.flagStatus
                  ) || migrationFlagStatusSelectOptions[0];
                const isDisabled = disabledRows.has(intakeEntity.id);

                return (
                  <tr
                    key={intakeEntity.id}
                    onDoubleClick={() =>
                      navigate(
                        `/organization/${organizationId}/intake/${intakeEntity.id}`
                      )
                    }
                    style={{ opacity: isDisabled ? 0.2 : 1 }}
                  >
                    <td></td>
                    <td>
                      <Typography level="body-sm">
                        {intakeEntity.intakeNumber}
                      </Typography>
                    </td>
                    <td>
                      <MigrationStatusChip status={intakeEntity.status} />
                    </td>
                    <td>
                      {intakeEntity.statistics.totalNumberOfFiles > 0 ? (
                        <Chip
                          sx={{ textAlign: "center" }}
                          variant="soft"
                          size="sm"
                          color={
                            migratedPercentage > 99.99 ? "success" : "primary"
                          }
                        >
                          {migratedPercentage % 1 > 0.99 ||
                          migratedPercentage % 1 < 0.01
                            ? `${migratedPercentage.toFixed(0)}%`
                            : `${migratedPercentage.toFixed(2)}%`}
                        </Chip>
                      ) : (
                        <Chip variant="soft" size="sm" color="danger">
                          No files
                        </Chip>
                      )}
                    </td>
                    <td>
                      <Chip variant="soft" size="sm" color="neutral">
                        {intakeEntity.statistics.totalNumberOfFiles ?? 0}
                      </Chip>
                    </td>
                    <td>
                      <Chip variant="soft" size="sm" color="neutral">
                        {intakeEntity.statistics.filesSizeInMb.toFixed(2)} MB
                      </Chip>
                    </td>

                    <td>
                      {intakeEntity.flagType ? (
                        <FlagTypeChip flagType={intakeEntity.flagType} />
                      ) : (
                        <></>
                      )}
                    </td>

                    <td>
                      <Typography
                        level="body-sm"
                        sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                      >
                        {intakeEntity.flaggedBy}
                      </Typography>
                    </td>

                    <td>
                      {intakeEntity.flagType &&
                      intakeEntity.flagType ===
                        FlagType.MigrationEntityWithMoreThan10KFiles ? (
                        <GenericSelect
                          size="sm"
                          options={migrationFlagStatusSelectOptions}
                          placeholder="Select status"
                          inputValue={migrationFlagStatusSelectOption}
                          setValue={(value) =>
                            handleFlagStatusModal(
                              intakeEntity.intakeNumber,
                              intakeEntity.id,
                              value.id,
                              true
                            )
                          }
                        />
                      ) : intakeEntity.flagType == null ||
                        intakeEntity.flagType ===
                          FlagType.MigrationEntityBlockedByUser ? (
                        <Button
                          sx={{
                            width: "100%",
                            maxWidth: 150,
                            justifyContent: "flex-start",
                            textAlign: "left",
                          }}
                          variant="soft"
                          aria-label="Block/Unblock"
                          disabled={isDisabled}
                          size="sm"
                          color={
                            intakeEntity.flagType ===
                            FlagType.MigrationEntityBlockedByUser
                              ? "lime"
                              : "warning"
                          }
                          startDecorator={
                            intakeEntity.flagType ===
                            FlagType.MigrationEntityBlockedByUser ? (
                              <LockOpenIcon />
                            ) : (
                              <BlockIcon />
                            )
                          }
                          onClick={() => {
                            intakeEntity.flagType ===
                            FlagType.MigrationEntityBlockedByUser
                              ? handleBlockModal(
                                  intakeEntity.intakeNumber,
                                  intakeEntity.id,
                                  false,
                                  true
                                )
                              : handleBlockModal(
                                  intakeEntity.intakeNumber,
                                  intakeEntity.id,
                                  true,
                                  true
                                );
                          }}
                        >
                          {intakeEntity.flagType ===
                          FlagType.MigrationEntityBlockedByUser
                            ? "Unblock"
                            : "Block"}
                        </Button>
                      ) : (
                        <></>
                      )}
                    </td>

                    <td>
                      {getActiveButtonForExplorer(intakeEntity, isDisabled)}
                    </td>
                  </tr>
                );
              })}
            </tbody>

            <tfoot>
              <tr>
                <td colSpan={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      justifyContent: "flex-end",
                    }}
                  >
                    <FormControl orientation="horizontal" size="sm">
                      <FormLabel>Rows per page:</FormLabel>
                      <Select
                        onChange={handleChangeRowsPerPage}
                        value={rowsPerPage}
                      >
                        <Option value={25}>25</Option>
                        <Option value={50}>50</Option>
                        <Option value={100}>100</Option>
                      </Select>
                    </FormControl>

                    <Typography textAlign="center" sx={{ minWidth: 80 }}>
                      {labelDisplayedRows({
                        from: rows === 0 ? 0 : page * rowsPerPage + 1,
                        to: getLabelDisplayedRowsTo(),
                        count: rows === -1 ? -1 : rows,
                      })}
                    </Typography>

                    <Box sx={{ display: "flex", gap: 1 }}>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={page === 0}
                        onClick={() => handleChangePage(page - 1)}
                        sx={{ bgcolor: "background.surface" }}
                      >
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={
                          rows !== -1
                            ? page >= Math.ceil(rows / rowsPerPage) - 1
                            : false
                        }
                        onClick={() => handleChangePage(page + 1)}
                        sx={{ bgcolor: "background.surface" }}
                      >
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </td>
              </tr>
            </tfoot>
          </Table>
        </Skeleton>
      </Sheet>

      {/* Flags other than block Modal */}
      {dirtyFlagStatus ? (
        <ConfirmIntakeFlagUpdateModal
          open={isFlagModalOpen}
          flagStatus={dirtyFlagStatus}
          intakeNumber={dirtyIntakeNumber}
          onClose={() => {
            handleFlagStatusModal(
              "",
              "",
              MigrationFlagStatus.UnResolved,
              false
            );
          }}
          onConfirm={async () => {
            await intakesRepository.updateIntakeFlag(
              dirtyIntakeId,
              dirtyFlagStatus
            );
            await intakesQuery.refetch();
            handleFlagStatusModal(
              "",
              "",
              MigrationFlagStatus.UnResolved,
              false
            );
          }}
        />
      ) : (
        <></>
      )}

      {/* Block Modal */}
      <ConfirmIntakeBlockUpdateModal
        open={isBlockModalOpen}
        isBlocked={isBlocked}
        intakeNumber={dirtyIntakeNumber}
        onClose={() => {
          handleBlockModal("", "", false, false);
        }}
        onConfirm={async () => {
          await intakesRepository.updateIntakeBlock(dirtyIntakeId, isBlocked);
          await intakesQuery.refetch();
          handleBlockModal("", "", false, false);
        }}
      />
    </React.Fragment>
  );
};

export default IntakesTable;
