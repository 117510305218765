import { Chip } from "@mui/joy";
import React from "react";
import {
  FlagType,
  FlagTypeChipColors,
  FlagTypeChipLabels,
} from "../../models/ClientConfiguration/FlaggingConfiguration";

type ChipBreakpoint = 'sm' | 'md' | 'lg';

interface FlagTypeChipProps {
  flagType: FlagType;
  size?: ChipBreakpoint;
}

const FlagTypeChip: React.FC<FlagTypeChipProps> = ({ 
  flagType, 
  size = "sm" 
}) => {
  
  return (
    <Chip variant="soft" size={size} color={FlagTypeChipColors[flagType]}>
      {FlagTypeChipLabels[flagType]}
    </Chip>
  );
};

export default FlagTypeChip;
