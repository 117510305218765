import {Link, Stack, Typography, useColorScheme } from '@mui/joy';
import React from 'react';
import DiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import LabelValuePair from "../common/LabelValuePair";
import {AuditLog, AuditLogType, AuditLogTypeLabels} from "../../models/AuditLog";

import {
  Modal,
  ModalDialog,
  DialogTitle,
  Divider,
  DialogContent,
  IconButton,
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from '@mui/joy';

import CloseIcon from '@mui/icons-material/Close';

const ViewConfigurationChangeAuditLogDetailsModal: React.FC<{
  auditLog: AuditLog | null;
  open: boolean;
  onClose: () => void;
  organizationId: string;
}> = ({
  auditLog,
  open, 
  onClose,
  organizationId
}) => {
  const { mode } = useColorScheme();

  const generateDynamicLink = (
    entityId: string | null,
    type: AuditLogType
  ) => {
    if (entityId === null)
      return `/organization/${organizationId}/audit-logs`;
    
    if (type === AuditLogType.ClientConfigurationChange) 
      return `/organization/${organizationId}/client-configuration/${entityId}`;
    
    if (type === AuditLogType.OrganizationConfigurationChange) 
      return `/organization/${entityId}/configuration`;
    
    return `/organization/${organizationId}/audit-logs`;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        width: {
          xs: '100vw',
          lg: 'calc(100vw + 15vw)',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {auditLog ? (
        <ModalDialog variant="outlined" sx={{ width: '65%', maxHeight: '90vh', overflow: 'hidden' }}>
          <DialogTitle>
            { AuditLogTypeLabels[auditLog.eventType] }
            <IconButton
              variant="plain"
              color="neutral"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 12,
                top: 12,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
  
          <Divider />
  
          <DialogContent sx={{ m: 0, pt: 1 }}>
            <Tabs defaultValue={0}>
              <TabList>
                <Tab>Changes</Tab>
                <Tab>Old Value</Tab>
                <Tab>New Value</Tab>
              </TabList>
              <TabPanel value={0}>
                <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                  <DiffViewer
                    showDiffOnly={true}
                    useDarkTheme={mode === 'dark'}
                    oldValue={JSON.stringify(JSON.parse(auditLog.oldValue!),null,2)}
                    newValue={JSON.stringify(JSON.parse(auditLog.newValue!),null,2)}
                    splitView={true}
                    compareMethod={DiffMethod.WORDS}
                    hideLineNumbers={false}
                  />
                </div>
              </TabPanel>
              <TabPanel value={1}>
                <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                  <DiffViewer
                    showDiffOnly={false}
                    useDarkTheme={mode === 'dark'}
                    oldValue={JSON.stringify(JSON.parse(auditLog.oldValue!),null,2)}
                    newValue={JSON.stringify(JSON.parse(auditLog.oldValue!),null,2)}
                    splitView={false}
                    compareMethod={DiffMethod.WORDS}
                    hideLineNumbers={true}
                  />
                </div>
              </TabPanel>
              <TabPanel value={2}>
                <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                  <DiffViewer
                    showDiffOnly={false}
                    useDarkTheme={mode === 'dark'}
                    oldValue={JSON.stringify(JSON.parse(auditLog.newValue!),null,2)}
                    newValue={JSON.stringify(JSON.parse(auditLog.newValue!),null,2)}
                    splitView={false}
                    compareMethod={DiffMethod.WORDS}
                    hideLineNumbers={true}
                  />
                </div>
              </TabPanel>
            </Tabs>
          </DialogContent>
  
          <Divider />

          <Stack direction="row" gap={0.5}>
            <Typography level="body-sm" sx={{ fontWeight: 'bold' }}>
              Entity ID:
            </Typography>
            <Typography level="body-sm">
              <Link href={generateDynamicLink(auditLog.entityId, auditLog.eventType)} underline="hover">
                {auditLog.entityId}
              </Link>
            </Typography>
          </Stack>

          {
            auditLog.eventType === AuditLogType.ClientConfigurationChange &&
            (() => {
              try {
                const parsedValue = JSON.parse(auditLog.newValue!);
                return parsedValue.Name ? (
                  <LabelValuePair label="Name" value={parsedValue.Name} />
                ) : null;
              } catch (error) {
                console.error("Invalid JSON format:", error);
                return null;
              }
            })()
          }

          <LabelValuePair
            label="Peroformed By"
            value={auditLog.performerEmailAddress}
          />

          <LabelValuePair
            label="Event Date"
            value={auditLog.eventDate}
          />
         
        </ModalDialog>
      ) : (
        <div />
      )}
    </Modal>
  );
};

export default ViewConfigurationChangeAuditLogDetailsModal;
