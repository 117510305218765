/* eslint-disable jsx-a11y/anchor-is-valid */
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import * as React from "react";

import { Button, IconButton, Stack, Tooltip, useTheme } from "@mui/joy";

import { Delete } from "@mui/icons-material";
import { useState } from "react";
import JobsRepository from "../../repositories/JobsRepository";
import { useQueryClient } from "react-query";
import { CaseFile } from "../../models/CaseFile";
import ConfirmCaseFilesDeletionModal from "./ConfirmCaseFilesDeletionModal";
interface CreateDeleteCaseFilesRequestTableProps {
  organizationId: string;
  caseId: string;
  caseNumber: string;
  newFilesForDeletion: CaseFile[];
  setNewCaseFilesForDeletion: React.Dispatch<React.SetStateAction<CaseFile[]>>;
}

const CreateDeleteCaseFilesRequestTable: React.FC<
  CreateDeleteCaseFilesRequestTableProps
> = ({
  organizationId,
  caseId,
  caseNumber,
  newFilesForDeletion,
  setNewCaseFilesForDeletion,
}) => {
  const theme = useTheme();

  const [confirmDeletionModalOpen, setConfirmDeletionModalOpen] =
    useState<boolean>(false);
  const allRows = [...newFilesForDeletion];
  const newIds = new Set(newFilesForDeletion.map((c) => c.id));
  const jobsRepository = new JobsRepository();
  const queryClient = useQueryClient();
  const getFileName = (filePath: string) => {
    return filePath.split("\\").pop();
  };
  return (
    <Stack gap={2} paddingTop={2} maxHeight={"40%"} minHeight={"40%"}>
      <Button
        disabled={!(newFilesForDeletion.length > 0)}
        onClick={() => setConfirmDeletionModalOpen(true)}
      >
        Delete files
      </Button>
      <Sheet
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          stickyFooter
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: "5%",
                  minWidth: 48,
                  textAlign: "center",
                  padding: "12px 6px",
                }}
              ></th>

              <th
                style={{
                  width: "40%",
                  padding: "12px 6px",
                }}
              >
                File Name
              </th>

              <th></th>
            </tr>
          </thead>

          <tbody>
            {allRows.map((caseFileForDeletion) => {
              return (
                <tr
                  key={caseFileForDeletion.id}
                  style={{
                    backgroundColor: newIds.has(caseFileForDeletion.id)
                      ? theme.palette.primary.plainHoverBg
                      : "inherit",
                  }}
                >
                  <td style={{ textAlign: "center", width: 120 }}></td>
                  <td>
                    <Tooltip title={caseFileForDeletion.filePath}>
                      <Typography level="body-sm">
                        {getFileName(caseFileForDeletion.filePath)}
                      </Typography>
                    </Tooltip>
                  </td>
                  <td
                    style={{
                      padding: "12px 6px",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      aria-label="Cancel"
                      size="sm"
                      color="danger"
                      onClick={() => {
                        setNewCaseFilesForDeletion((prevRows) =>
                          prevRows.filter(
                            (entity) => entity.id !== caseFileForDeletion.id
                          )
                        );
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Sheet>
      {confirmDeletionModalOpen ? (
        <ConfirmCaseFilesDeletionModal
          open={confirmDeletionModalOpen}
          caseNumber={caseNumber}
          filePaths={newFilesForDeletion.map((f) => f.filePath)}
          onClose={() => {
            setConfirmDeletionModalOpen(false);
          }}
          onConfirm={async () => {
            await jobsRepository.startCaseFilesDeletion(
              organizationId,
              caseId,
              newFilesForDeletion.map((c) => c.id)
            );
            queryClient.invalidateQueries("jobs");
            queryClient.invalidateQueries([
              "deleteCaseFiles",
              organizationId,
              caseId,
            ]);
            queryClient.invalidateQueries(["caseFiles", caseId]);
            setNewCaseFilesForDeletion([]);
            setConfirmDeletionModalOpen(false);
          }}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default CreateDeleteCaseFilesRequestTable;
