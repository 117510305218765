import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
  Tabs,
  Tab,
  TabPanel,
  TabList,
  tabClasses,
  Divider,
} from "@mui/joy";
import { useParams } from "react-router-dom";
import CasesTable from "../components/Cases/CasesTable";
import DeleteCasesTable from "../components/Cases/DeleteCasesTable";
import { useState } from "react";
import { DeleteCaseRequest } from "../models/Jobs/JobRequest";
import CreateExportCasesToCsvTable from "../components/Cases/CreateExportCasesToCsvTable";
import { Case } from "../models/Case";
import ExportCasesToCsvStatusTable from "../components/Cases/ExportCasesToCsvStatusTable";

export enum ActiveCaseJobsExplorer {
  DeleteCases = 0,
  ExportCsv = 1,
}

const CasesPage = () => {
  const { organizationId } = useParams();
  const [newCasesForDeletion, setNewCasesForDeletion] = useState<
    DeleteCaseRequest[]
  >([]);
  const [newCasesForExport, setNewCasesForExport] = useState<Case[]>([]);
  const [selectedTab, setSelectedTab] = useState<ActiveCaseJobsExplorer>(
    ActiveCaseJobsExplorer.DeleteCases
  );
  return (
    <Stack maxHeight={"92vh"}>
      <Box
        sx={{
          display: "flex",
          my: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">Cases</Typography>
      </Box>
      <Grid container spacing={2} display={"inline-flex"} maxHeight={"100%"}>
        <Grid xs={12} lg={9} display={"flex"} maxHeight={"100%"}>
          <Card>
            <CasesTable
              organizationId={organizationId ?? ""}
              activeJobsExplorer={selectedTab}
              newCasesForDeletion={newCasesForDeletion}
              setNewCasesForDeletion={setNewCasesForDeletion}
              newCasesForExport={newCasesForExport}
              setNewCasesForExport={setNewCasesForExport}
            />
          </Card>
        </Grid>
        <Grid
          xs={12}
          lg={3}
          display={"flex"}
          maxHeight={"100%"}
          height={"100%"}
        >
          <Card sx={{ width: "100%", padding: 0 }}>
            <Tabs
              value={selectedTab}
              sx={{
                maxHeight: "100%",
                borderRadius: "sm",
                overflow: "auto",
                height: "100%",
              }}
              onChange={(event, newValue) =>
                setSelectedTab(newValue as ActiveCaseJobsExplorer)
              }
            >
              <TabList
                disableUnderline
                tabFlex={1}
                sx={{
                  [`& .${tabClasses.root}`]: {
                    fontSize: "sm",
                    fontWeight: "md",
                    [`&[aria-selected="true"]`]: {
                      bgcolor: "background.surface",
                    },
                    [`&.${tabClasses.focusVisible}`]: {
                      outlineOffset: "-4px",
                    },
                  },
                }}
              >
                <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                  Delete cases
                </Tab>
                <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                  Export csv
                </Tab>
              </TabList>
              <TabPanel
                value={ActiveCaseJobsExplorer.DeleteCases}
                sx={{
                  flex: 1,
                  overflow: "auto",
                  maxHeight: "100%",
                  paddingTop: 0,
                }}
              >
                <Card
                  sx={{
                    padding: 0,
                    border: 0,
                    width: "100%",
                    maxHeight: "100%",
                  }}
                >
                  <DeleteCasesTable
                    organizationId={organizationId ?? ""}
                    newCasesForDeletion={newCasesForDeletion}
                    setNewCasesForDeletion={setNewCasesForDeletion}
                  />
                </Card>
              </TabPanel>
              <TabPanel
                value={ActiveCaseJobsExplorer.ExportCsv}
                sx={{
                  flex: 1,
                  overflow: "auto",
                  maxHeight: "100%",
                  height: "100%",
                  paddingTop: 0,
                }}
              >
                <Stack height={"100%"} spacing={2}>
                  {newCasesForExport.length > 0 && (
                    <>
                      <Divider sx={{ paddingTop: 2 }}>
                        <b>New</b>
                      </Divider>
                      <CreateExportCasesToCsvTable
                        organizationId={organizationId ?? ""}
                        newCasesForExport={newCasesForExport}
                        setNewCasesForExport={setNewCasesForExport}
                      />
                      <Divider>
                        <b>Existing exports</b>
                      </Divider>
                    </>
                  )}
                  <Card
                    sx={{
                      padding: 0,
                      border: 0,
                      width: "100%",
                      height: "50%",
                    }}
                  >
                    <ExportCasesToCsvStatusTable
                      organizationId={organizationId ?? ""}
                    />
                  </Card>
                </Stack>
              </TabPanel>
            </Tabs>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default CasesPage;
