import React from "react";
import { MigrationFlagStatus } from "../../models/Flags/MigrationFlagStatus";
import ConfirmationModal from "../common/ConfirmationModal";

interface ConfirmCaseFlagUpdateModalProps {
  open: boolean;
  caseNumber: string;
  flagStatus: MigrationFlagStatus;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmCaseFlagUpdateModal: React.FC<ConfirmCaseFlagUpdateModalProps> = ({
  open,
  caseNumber,
  flagStatus,
  onClose,
  onConfirm,
}) => {
  const getMessage = (): string => {
    switch (flagStatus) {
      case MigrationFlagStatus.UnResolved:
        return `Are you sure you want to block case ${caseNumber} migration?`;
      case MigrationFlagStatus.Resolved:
        return `Are you sure you want to allow case ${caseNumber} migration?`;
      case MigrationFlagStatus.Acknowledged:
        return `Are you sure you want to acknowledge the flag on ${caseNumber} migration?`;
      default:
        return '';
    }
  };

  const getStatusColor = (): "danger" | "lime" | "yellow" | "neutral" => {
    switch (flagStatus) {
      case MigrationFlagStatus.UnResolved:
        return 'danger';
      case MigrationFlagStatus.Resolved:
        return 'lime';
      case MigrationFlagStatus.Acknowledged:
        return 'yellow';
      default:
        return 'neutral';
    }
  };

  const getButtonText = (): string => {
    switch (flagStatus) {
      case MigrationFlagStatus.UnResolved:
        return 'Block';
      case MigrationFlagStatus.Resolved:
        return 'Allow';
      case MigrationFlagStatus.Acknowledged:
        return 'Acknowledge';
      default:
        return 'Confirm';
    }
  };

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      message={getMessage()}
      statusColor={getStatusColor()}
      buttonText={getButtonText()}
    />
  );
};

export default ConfirmCaseFlagUpdateModal;
