import { Link, Stack, Typography, useColorScheme } from "@mui/joy";
import React from "react";
import LabelValuePair from "../common/LabelValuePair";
import {
  AuditLog,
  AuditLogType,
  AuditLogTypeLabels,
} from "../../models/AuditLog";

import {
  Modal,
  ModalDialog,
  DialogTitle,
  Divider,
  DialogContent,
  IconButton,
} from "@mui/joy";

import CloseIcon from "@mui/icons-material/Close";

const ViewExportEntitiesAuditLogDetailsModal: React.FC<{
  auditLog: AuditLog | null;
  open: boolean;
  onClose: () => void;
  organizationId: string;
}> = ({ auditLog, open, onClose, organizationId }) => {
  const { mode } = useColorScheme();

  const generateDynamicLink = (entityId: string | null, type: AuditLogType) => {
    if (entityId === null) return `/organization/${organizationId}/audit-logs`;

    if (type === AuditLogType.CasesExport)
      return `/organization/${organizationId}/case/${entityId}`;

    return `/organization/${organizationId}/audit-logs`;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        width: {
          xs: "100vw",
          lg: "calc(100vw + 15vw)",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {auditLog ? (
        <ModalDialog
          variant="outlined"
          sx={{ width: "65%", maxHeight: "90vh", overflow: "hidden" }}
        >
          <DialogTitle>
            {AuditLogTypeLabels[auditLog.eventType]}
            <IconButton
              variant="plain"
              color="neutral"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 12,
                top: 12,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Divider />

          <DialogContent sx={{ m: 0 }}>
            <Stack direction="column" gap={1}>
              <Typography level="body-sm" sx={{ fontWeight: "bold" }}>
                Case numbers:
              </Typography>
              {auditLog.additionalData ? (
                (() => {
                  try {
                    const additionalDataArray = JSON.parse(
                      auditLog.additionalData
                    );
                    if (Array.isArray(additionalDataArray)) {
                      return additionalDataArray.map(
                        (
                          caseData: { Id: string; CaseNumber: string },
                          index: number
                        ) => (
                          <Typography key={index} level="body-sm">
                            <Link
                              href={generateDynamicLink(
                                caseData.Id,
                                auditLog.eventType
                              )}
                              underline="hover"
                            >
                              {caseData.CaseNumber}
                            </Link>
                          </Typography>
                        )
                      );
                    }
                    return (
                      <Typography level="body-sm">
                        Invalid data format
                      </Typography>
                    );
                  } catch (error) {
                    return (
                      <Typography level="body-sm">
                        Failed to parse additionalData
                      </Typography>
                    );
                  }
                })()
              ) : (
                <></>
              )}
            </Stack>
          </DialogContent>

          <Divider />

          <LabelValuePair
            label="Peroformed By"
            value={auditLog.performerEmailAddress}
          />

          <LabelValuePair label="Event Date" value={auditLog.eventDate} />
        </ModalDialog>
      ) : (
        <div />
      )}
    </Modal>
  );
};

export default ViewExportEntitiesAuditLogDetailsModal;
