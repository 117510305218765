import { ColorPaletteProp } from "@mui/joy";

export enum JobType {
  Delete = "delete",
  ExportCsv = "exportCsv",
}
export const JobTypeLabels: Record<JobType, string> = {
  [JobType.Delete]: "Delete",
  [JobType.ExportCsv]: "Export Csv",
};

export const JobTypeColors: Record<JobType, ColorPaletteProp> = {
  [JobType.Delete]: "danger",
  [JobType.ExportCsv]: "success",
};
