import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  Tab,
  tabClasses,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from "@mui/joy";
import { useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import CaseFilesTable, {
  CaseFilesTableRef,
} from "../components/Case/CaseFilesTable";
import UpdateCaseFlagModal from "../components/Cases/ConfirmCaseFlagUpdateModal";
import MigrationStatusChip from "../components/common/MigrationStatusChip";
import { CaseWithStatistics } from "../models/Case";
import {
  MigrationStatus,
  MigrationStatusColors,
} from "../models/MigrationStatus";
import CasesRepository from "../repositories/CasesRepository";
import GenericSelect from "../components/common/GenericSelect";
import {
  MigrationFlagStatus,
  migrationFlagStatusSelectOptions,
} from "../models/Flags/MigrationFlagStatus";
import React from "react";
import DeleteCaseFilesStatusTable from "../components/Case/DeleteCaseFilesStatusTable";
import { CaseFile } from "../models/CaseFile";
import CreateDeleteCaseFilesRequestTable from "../components/Case/CreateDeleteCaseFilesRequestTable";
import ExportCaseFilesToCsvStatusTable from "../components/Case/ExportCaseFilesToCsvStatusTable";
import ConfirmCaseFilesExportToCsvModal from "../components/Case/ConfirmCaseFilesExportToCsvModal";
export enum ActiveCaseFilesJobsExplorer {
  DeleteCaseFiles = 0,
  ExportCsv = 1,
}
const CasePage = () => {
  const { organizationId, caseId } = useParams();
  const casesRepository = new CasesRepository();
  const [caseEntity, setCase] = useState<CaseWithStatistics | undefined>(
    undefined
  );
  const [dirtyFlagStatus, setDirtyFlagStatus] = useState<MigrationFlagStatus>();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [isConfirmExportModalOpen, setIsConfirmExportModalOpen] =
    React.useState<boolean>(false);
  const [newCaseFilesForDeletion, setNewCaseFilesForDeletion] = useState<
    CaseFile[]
  >([]);
  const [selectedTab, setSelectedTab] = useState<ActiveCaseFilesJobsExplorer>(
    ActiveCaseFilesJobsExplorer.DeleteCaseFiles
  );
  const queryClient = useQueryClient();
  const caseQuery = useQuery(
    ["case", caseId],
    async () => {
      return await casesRepository.getCase(caseId ?? "");
    },
    {
      cacheTime: 2 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
      refetchOnMount: true,
    }
  );
  const caseFilesTableRef = useRef<CaseFilesTableRef>(null);

  useEffect(() => {
    if (caseQuery.data) {
      setCase(caseQuery.data);
    }
  }, [caseQuery.data, caseId]);

  const { isLoading, isError } = caseQuery;
  if (isLoading || isError || !!!caseEntity) {
    return <></>;
  }

  const handleFlagStatusModal = (
    flagStatus: MigrationFlagStatus,
    isOpen: boolean
  ) => {
    setIsModalOpen(isOpen);
    setDirtyFlagStatus(flagStatus);
  };
  const startCurrentViewExportToCsv = () => {
    caseFilesTableRef.current?.exportCurrentViewToCsv();
  };
  const migrationFlagStatusSelectOption =
    migrationFlagStatusSelectOptions.find(
      (option) => option.id === caseEntity?.flagStatus
    ) || migrationFlagStatusSelectOptions[0];
  const migratedPercentage =
    caseEntity.statistics.totalNumberOfFiles > 0 &&
    caseEntity.statistics.filesSizeInMb > 0
      ? (caseEntity.statistics.filesMigrated /
          caseEntity.statistics.totalNumberOfFiles) *
        100
      : 0;

  return (
    <Stack maxHeight={"92vh"}>
      <Box
        sx={{
          width: "100%",
          my: 1,
          gap: 1,
          flexWrap: "wrap",
        }}
      >
        <Stack>
          <Typography level="h2">
            Case {caseEntity.caseNumber}{" "}
            <MigrationStatusChip status={caseEntity.status} size={"md"} />
          </Typography>
          <Stack
            width="100%"
            flex={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Stack
              flex={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Not Started</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.NotStarted]}
                >
                  {caseEntity.statistics.totalNumberOfFiles -
                    (caseEntity.statistics.filesMigrated +
                      caseEntity.statistics.filesFlagged +
                      caseEntity.statistics.filesInterrupted +
                      caseEntity.statistics.filesNotFound)}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Migrated</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.Finished]}
                >
                  {caseEntity.statistics.filesMigrated}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Interrupted</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.Interrupted]}
                >
                  {caseEntity.statistics.filesInterrupted}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Not Found</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.NotFound]}
                >
                  {caseEntity.statistics.filesNotFound}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Flagged</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.Flagged]}
                >
                  {caseEntity.statistics.filesFlagged}
                </Chip>
              </FormControl>

              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Files Count</FormLabel>
                <Chip variant="soft" size="sm" color="neutral">
                  {caseEntity.statistics.totalNumberOfFiles}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Size (MB)</FormLabel>
                <Chip variant="soft" size="sm" color="primary">
                  {caseEntity.statistics.filesSizeInMb.toFixed(2)}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Progress</FormLabel>
                <Chip
                  sx={{ textAlign: "center" }}
                  variant="soft"
                  size="sm"
                  color={migratedPercentage > 99.99 ? "success" : "primary"}
                >
                  {migratedPercentage % 1 > 0.99 ||
                  migratedPercentage % 1 < 0.01
                    ? `${migratedPercentage.toFixed(0)}%`
                    : `${migratedPercentage.toFixed(2)}%`}
                </Chip>
              </FormControl>
            </Stack>

            {caseEntity.flagType ? (
              <GenericSelect
                width={"260px"}
                size="md"
                options={migrationFlagStatusSelectOptions}
                placeholder="Select status"
                inputValue={migrationFlagStatusSelectOption}
                setValue={(value) => handleFlagStatusModal(value.id, true)}
              />
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
      </Box>
      <Grid container spacing={2} display={"inline-flex"} maxHeight={"100%"}>
        <Grid xs={12} lg={9} display={"flex"} maxHeight={"100%"}>
          <Card>
            <CaseFilesTable
              ref={caseFilesTableRef}
              organizationId={organizationId ?? ""}
              caseId={caseId ?? ""}
              activeJobsExplorer={selectedTab}
              newCaseFilesForDeletion={newCaseFilesForDeletion}
              setNewCasesFilesForDeletion={setNewCaseFilesForDeletion}
            />
          </Card>
        </Grid>
        <Grid
          xs={12}
          lg={3}
          display={"flex"}
          maxHeight={"100%"}
          height={"100%"}
        >
          <Card sx={{ width: "100%", padding: 0 }}>
            <Tabs
              value={selectedTab}
              sx={{
                maxHeight: "100%",
                borderRadius: "sm",
                overflow: "auto",
                height: "100%",
              }}
              onChange={(event, newValue) =>
                setSelectedTab(newValue as ActiveCaseFilesJobsExplorer)
              }
            >
              <TabList
                disableUnderline
                tabFlex={1}
                sx={{
                  [`& .${tabClasses.root}`]: {
                    fontSize: "sm",
                    fontWeight: "md",
                    [`&[aria-selected="true"]`]: {
                      bgcolor: "background.surface",
                    },
                    [`&.${tabClasses.focusVisible}`]: {
                      outlineOffset: "-4px",
                    },
                  },
                }}
              >
                <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                  Delete files
                </Tab>
                <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                  Export csv
                </Tab>
              </TabList>
              <TabPanel
                value={ActiveCaseFilesJobsExplorer.DeleteCaseFiles}
                sx={{
                  flex: 1,
                  overflow: "auto",
                  maxHeight: "100%",
                  height: "100%",
                  paddingTop: 0,
                }}
              >
                <Stack height={"100%"} spacing={2}>
                  {newCaseFilesForDeletion.length > 0 && (
                    <>
                      <Divider sx={{ paddingTop: 2 }}>
                        <b>New</b>
                      </Divider>
                      <CreateDeleteCaseFilesRequestTable
                        organizationId={organizationId ?? ""}
                        caseId={caseId ?? ""}
                        caseNumber={caseEntity.caseNumber}
                        newFilesForDeletion={newCaseFilesForDeletion}
                        setNewCaseFilesForDeletion={setNewCaseFilesForDeletion}
                      />
                      <Divider>
                        <b>Deletion status</b>
                      </Divider>
                    </>
                  )}
                  <Card
                    sx={{
                      padding: 0,
                      border: 0,
                      width: "100%",
                      height: "50%",
                    }}
                  >
                    <DeleteCaseFilesStatusTable
                      organizationId={organizationId ?? ""}
                      caseId={caseId ?? ""}
                    />
                  </Card>
                </Stack>
              </TabPanel>
              <TabPanel
                value={ActiveCaseFilesJobsExplorer.ExportCsv}
                sx={{
                  flex: 1,
                  overflow: "auto",
                  maxHeight: "100%",
                  height: "100%",
                  paddingTop: 0,
                }}
              >
                <Stack height={"100%"} spacing={2}>
                  <Card
                    sx={{
                      padding: "1em 0 0 0",
                      border: 0,
                      width: "100%",
                      height: "50%",
                    }}
                  >
                    <Button onClick={() => setIsConfirmExportModalOpen(true)}>
                      Export current files view
                    </Button>
                    <ExportCaseFilesToCsvStatusTable
                      organizationId={organizationId ?? ""}
                      caseId={caseId ?? ""}
                    />
                  </Card>
                </Stack>
              </TabPanel>
            </Tabs>
          </Card>
        </Grid>
      </Grid>
      {/* Flag Modal */}
      {dirtyFlagStatus && caseEntity.flagType ? (
        <UpdateCaseFlagModal
          open={isModalOpen}
          flagStatus={dirtyFlagStatus}
          caseNumber={caseEntity.caseNumber}
          onClose={() => {
            handleFlagStatusModal(MigrationFlagStatus.UnResolved, false);
          }}
          onConfirm={async () => {
            if (caseId !== undefined) {
              await casesRepository.updateCaseFlag(
                caseEntity.id,
                dirtyFlagStatus
              );
              await queryClient.invalidateQueries(["cases", organizationId]);
              await queryClient.invalidateQueries(["case", caseEntity.id]);
              handleFlagStatusModal(MigrationFlagStatus.UnResolved, false);
            }
          }}
        />
      ) : (
        <></>
      )}
      {/* Confirm CSV export modal */}
      {isConfirmExportModalOpen ? (
        <ConfirmCaseFilesExportToCsvModal
          open={isConfirmExportModalOpen}
          onClose={() => {
            setIsConfirmExportModalOpen(false);
          }}
          onConfirm={async () => {
            startCurrentViewExportToCsv();
            setIsConfirmExportModalOpen(false);
          }}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default CasePage;
